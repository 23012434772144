import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../../Context/User/userContext";
import Api from "../../../Helpers/ApiHelper";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";

const KOTDisplay = () => {
  const context = useContext(UserContext);
  const { user, setIsLoading, isMobile, setAlertText, selectedHotel } = context;

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedTable, setSelectedTable] = useState(null);
  const [kotData, setKotData] = useState({});

  const [firstRunFlag, setFirstRunFlag] = useState(true);

  useEffect(() => {
    if (!location.state) {
      navigate("/fbx01ts");
      return;
    }
    const data = location.state;
    setSelectedTable(data);
  }, [location, navigate]);

  useEffect(() => {
    if (selectedTable) {
      loadKotData();
    }
    // eslint-disable-next-line
  }, [selectedTable]);

  const loadKotData = async () => {
    if (!selectedHotel) return;
    if (!selectedTable) {
      navigate("/fbx02ts");
      return;
    }
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: selectedTable.OmId.toString(),
      str4: selectedTable.OutletId.toString(),
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/fnb/GetRunningKot`, postData, {
        headers: headers,
      });
      setIsLoading(false);
      if (response.status === 200) {
        setFirstRunFlag(false);
        setKotData(response.data);        
      }
    } catch (error) {
      setIsLoading(false);
    }    
  };

  useEffect(() => {
    if (selectedHotel && Boolean(selectedHotel.str1)) {
      if (!firstRunFlag) {
        navigate("/fbx01ts");
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel]);

  const formatCurrency = (numberString) => {
    const number = parseFloat(numberString);
    const formattedNumber = number.toFixed(2);
    return "₹" + formattedNumber;
  };

  function formatDateTime(datetimeString) {
    const formattedDatetime = format(
      new Date(datetimeString),
      "dd MMM, yyyy hh:mm a"
    );
    return formattedDatetime;
  }

  const getTableColor = (inputString) => {
    switch (inputString.toLowerCase()) {
      case "bill":
        return "#ffb6c1";
      case "settlement":
        return "#90ee90";
      default:
        return "#f5f5f5";
    }
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">
              {selectedTable ? selectedTable.OutletName : ""} :{" "}
              {selectedTable ? selectedTable.TableNo : ""}
            </h4>
          </div>
        </div>

        <div className="row g-3">
          <div className="col-md-12 col-12 card-top">
            {kotData.ItemSummary && selectedTable && (
              <div className="card card-one d-card border border-dark">
                <div className="card-body p-3" style={{ color: "black" }}>
                  <div className="row">
                    <div className="col">
                      <p>
                        <span className="text-muted fixed-width-span">
                          Table
                        </span>
                        <span className="text-muted"> : </span>{" "}
                        {selectedTable.TableNo}
                      </p>
                      <p>
                        <span className="text-muted fixed-width-span">
                          Order Date
                        </span>
                        <span className="text-muted"> : </span>{" "}
                        {formatDateTime(kotData.ItemSummary.OrderDateTime)}
                      </p>
                      <p>
                        <span className="text-muted fixed-width-span">
                          Amount
                        </span>
                        <span className="text-muted"> : </span>{" "}
                        {formatCurrency(selectedTable.NetAmount)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`row mt-3 g-3 ${isMobile ? "" : "card-300"}`}>
          {kotData.ItemList &&
            kotData.ItemList.map((item) => (
              <div className="col-md-12 col-12" key={item.ItemId}>
                <div className="card card-one d-card">
                  <div className="card-body p-3" style={{ color: "black" }}>
                    <div className="d-flex justify-content-start mb-2">
                      <h6 className="fw-bold mb-0">
                        {item.ItemName}
                      </h6>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                        <p>
                          <span className="text-muted fixed-width-span">
                            Rate
                          </span>
                          <span className="text-muted"> : </span>
                          {formatCurrency(item.ItemPrice)}
                        </p>
                        <p>
                            <span className="text-muted fixed-width-span">
                              Qty
                            </span>
                            <span className="text-muted"> : </span>
                            {item.Qty}
                          </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {kotData.length === 0 && (
            <div className="col">
              <div className="card text-dark bg-light mb-2">
                <div className="card-body pb-0">
                  <p className="text-center">
                    <strong>No Record Found</strong>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default KOTDisplay;
