import React from "react";

const MainBackdrop = () => {
  const HandleClick = () => {
    const body = document.querySelector("body");
    body.classList.remove("sidebar-show");
    body.classList.remove("sideright-show");
  };
  return <div className="main-backdrop" onClick={HandleClick}></div>;
};

export default MainBackdrop;
