import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../Context/User/userContext";
import Api from "../../Helpers/ApiHelper";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import DatePicker from "react-datepicker";

const GSTReport = () => {
  const context = useContext(UserContext);
  const { user, setIsLoading, isMobile, setAlertText, selectedHotel } = context;

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [outletList, setOutletList] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [billNo, setBillNo] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [isComplementary, setIsComplementary] = useState(false);
  const [isCancelBill, setIsCancelBill] = useState(false);
  const [data, setData] = useState([]);
  const [settlementData, setSettlementData] = useState([]);

  const loadOutlets = async () => {
    if (!selectedHotel) return;
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/fnb/GetOutletList`, postData, {
        headers: headers,
      });
      if (response.status === 200) {
        setOutletList(response.data);
        setSelectedOutlet("");
      }
    } catch (error) {}
  };

  const loadData = async () => {
    if (!selectedHotel) return;

    setIsLoading(true);
    const postData = {
      suid: user.token,
      hoteid: selectedHotel.str1,
      FromDate: format(fromDate, "dd/MM/yyyy"),
      ToDate: format(toDate, "dd/MM/yyyy"),
      OutletGroupId: 0,
      OutletId: selectedOutlet,
      BillNo: billNo,
      CustomerName: customerName,
      MultiSettleFlag: 0,
      type: "0",
      IsComplementary: isComplementary ? 1 : 0,
      ShowCancelBill: isCancelBill ? 1 : 0,
      MopId: 0,
      AccountId: 0,
      IsWithSettlement: 0,
      TaxType: 0,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(
        `/api/fnb/GetHotelGSTReportData`,
        postData,
        {
          headers: headers,
        }
      );
      setIsLoading(false);
      if (response.status === 200) {
        if (response.data.GSTData && response.data.GSTData.length > 0) {
          const dataWithKeys = response.data.GSTData.map((item) => ({
            ...item,
            key: uuidv4(),
          }));
          setData(dataWithKeys);
        } else {
          setData([]);
        }
        if (
          response.data.SettlementData &&
          response.data.SettlementData.length > 0
        ) {
          const sumByLabel = response.data.SettlementData.reduce(
            (acc, item) => {
              const { commondesc, settledAmount } = item;
              acc[commondesc] = (acc[commondesc] || 0) + settledAmount;
              return acc;
            },
            {}
          );
          const resultData = Object.entries(sumByLabel).map(
            ([commondesc, settledAmount]) => ({
              commondesc,
              settledAmount,
            })
          );
          setSettlementData(resultData);
        }
      } else {
        setSettlementData([]);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedHotel && Boolean(selectedHotel.str1)) {
      loadOutlets().then(() => {
        loadData();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel]);

  function formatDateTime(datetimeString) {
    const formattedDatetime = format(
      new Date(datetimeString),
      "dd MMM, yyyy hh:mm a"
    );
    return formattedDatetime;
  }

  const formatCurrency = (numberString) => {
    const number = parseFloat(numberString);
    const formattedNumber = number.toFixed(2);
    return "₹" + formattedNumber;
  };

  const handleLoadButtonClick = (e) => {
    loadData();
  };

  const getOutletName = (OutletId) => {
    return (
      outletList.find((outlet) => outlet.OutletId === OutletId)?.OutletName ||
      ""
    );
  };

  const extractBillNumber = (string) => {
    const parts = string.split("/");
    const numberPart = parts[parts.length - 1];
    return numberPart;
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">GST Report</h4>
          </div>
        </div>

        <div className={`row ${isMobile ? "" : "card-top"}`}>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">From Date</label>
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">To Date</label>
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">Outlet</label>
            <select
              className="form-select"
              value={selectedOutlet}
              onChange={(e) => setSelectedOutlet(e.target.value)}
            >
              <option value="">--All--</option>
              {outletList.map((x) => (
                <option key={x.OutletId} value={x.OutletId}>
                  {x.OutletName}
                </option>
              ))}
            </select>
          </div>
          <div
            className={`col-12 col-sm-3 mt-2 ${isMobile ? "order-last" : ""}`}
          >
            <button
              type="button"
              className="btn btn-dark"
              style={{ width: "100%", marginTop: isMobile ? 0 : "28px" }}
              onClick={handleLoadButtonClick}
            >
              Load Report
            </button>
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">Bill No</label>
            <input
              type="text"
              className="form-control"
              value={billNo}
              onChange={(e) => setBillNo(e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">Customer</label>
            <input
              type="text"
              className="form-control"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
            />
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <div className="form-check">
              <label
                className="form-check-label"
                htmlFor="isComplemenotryCheckbox"
              >
                Complemenotry
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="isComplemenotryCheckbox"
                checked={isComplementary}
                onChange={(e) => setIsComplementary(e.target.checked)}
              />
            </div>
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <div className="form-check">
              <label
                className="form-check-label"
                htmlFor="isCancelBillCheckbox"
              >
                Cancelled Bill
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="isCancelBillCheckbox"
                checked={isCancelBill}
                onChange={(e) => setIsCancelBill(e.target.checked)}
              />
            </div>
          </div>
        </div>

        <div className={`row mt-3 g-3 ${isMobile ? "" : "card-500"}`}>
          {data.map((item) => (
            <div className="col-md-12 col-12" key={item.key}>
              <div className="card card-one d-card">
                <div className="card-body p-3" style={{ color: "black" }}>
                  <div className="d-flex justify-content-start mb-2">
                    <h6 className="fw-bold mb-0">
                      {extractBillNumber(item.BillNo)}
                    </h6>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <h6 className="fw-normal">{item.BillDate}</h6>

                      {item.ClientName && (
                        <p>
                          <span className="text-muted fixed-width-span">
                            Customer
                          </span>
                          <span className="text-muted"> : </span>
                          {item.ClientName}
                        </p>
                      )}

                      <p>
                        <span className="text-muted fixed-width-span">
                          Outlet
                        </span>
                        <span className="text-muted"> : </span>
                        {getOutletName(item.OutletId)}
                      </p>

                      <p>
                        <span className="text-muted fixed-width-span">
                          Net Amount
                        </span>
                        <span className="text-muted"> : </span>
                        {formatCurrency(item.NetAmount)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {data.length === 0 && (
            <div className="col">
              <div className="card text-dark bg-light mb-2">
                <div className="card-body pb-0">
                  <p className="text-center">
                    <strong>No Record Found</strong>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

{(settlementData.length>0) && (
  <div className="main-footer mt-5" style={{paddingBottom: '10px'}}>
          <div className="row w-100">
            <div className="col">
            <div className="row flex-nowrap overflow-auto">
            {settlementData.map((item, index) => (
              <div key={index} className="col-6 col-sm-3">
                <div className="card">
                  <div className="card-body">
                    <p className="card-text text-center">{item.commondesc}</p>
                    <p className="card-text text-center">{formatCurrency(item.settledAmount)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
            </div>
          </div>
        </div>
)}
        
      </div>
    </>
  );
};

export default GSTReport;
