import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import UserState from "./Context/User/UserState";
import MainBackdrop from "./Layouts/MainBackdrop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <UserState>
      <App />
      <MainBackdrop/>
    </UserState>
  </BrowserRouter>
);
