import axios from "axios";

const url = process.env.REACT_APP_API_URL;

const ApiHelper = axios.create({
  baseURL: url,
});

ApiHelper.interceptors.response.use(
  response => response,
  error => {    
    if (error.response && error.response.status === 401) {      
      window.location.href = '/login';
    }
    // Pass the error to the caller to handle it further
    return Promise.reject(error);
  }
);

export default ApiHelper;