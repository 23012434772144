import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../Context/User/userContext";
import Api from "../../Helpers/ApiHelper";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import DatePicker from "react-datepicker";

const NegativeKotReport = () => {
  const context = useContext(UserContext);
  const { user, setIsLoading, isMobile, setAlertText, selectedHotel } = context;

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [outletList, setOutletList] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(0);
  const [salesGroupList, setSalesGroupList] = useState([]);
  const [selectedSalesGroup, setSelectedSalesGroup] = useState("0");
  const [reportType, setReportType] = useState("0");
  const [data, setData] = useState([]);

  const loadOutlets = async () => {
    if (!selectedHotel) return;
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/fnb/GetOutletList`, postData, {
        headers: headers,
      });
      if (response.status === 200) {
        setOutletList(response.data);
        setSelectedOutlet(0);
      }
    } catch (error) {}
  };

  const loadSalesGroupList = async () => {
    if (!selectedHotel) return;
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(
        `/api/store/GetSalesGroupList`,
        postData,
        {
          headers: headers,
        }
      );
      if (response.status === 200) {
        setSalesGroupList(response.data);
        setSelectedSalesGroup("0");
      }
    } catch (error) {}
  };

  const loadData = async () => {
    if (!selectedHotel) return;

    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: reportType,
      str4: format(fromDate, "dd/MM/yyyy"),
      str5: format(toDate, "dd/MM/yyyy"),
      str6: selectedOutlet,
      str7: selectedSalesGroup,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(
        `/api/fnb/GetNegativeKotItems`,
        postData,
        {
          headers: headers,
        }
      );
      setIsLoading(false);
      if (response.status === 200) {
        const dataWithKeys = response.data.map((item) => ({
          ...item,
          key: uuidv4(),
        }));
        setData(dataWithKeys);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedHotel && Boolean(selectedHotel.str1)) {
      loadOutlets().then(() => {
        loadSalesGroupList().then(() => {
          loadData();
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel]);

  function formatDateTime(datetimeString) {
    const formattedDatetime = format(
      new Date(datetimeString),
      "dd MMM, yyyy hh:mm a"
    );
    return formattedDatetime;
  }

  const formatCurrency = (numberString) => {
    const number = parseFloat(numberString);
    const formattedNumber = number.toFixed(2);
    return "₹" + formattedNumber;
  };

  const handleLoadButtonClick = (e) => {
    loadData();
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Negative KOT Report</h4>
          </div>
        </div>

        <div className={`row ${isMobile ? "" : "card-top"}`}>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">From Date</label>
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">To Date</label>
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">Report Type</label>
            <select
              className="form-select"
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
            >
              <option value="0">Summary</option>
              <option value="1">Detail</option>
            </select>
          </div>
          <div
            className={`col-12 col-sm-3 mt-2 ${isMobile ? "order-last" : ""}`}
          >
            <button
              type="button"
              className="btn btn-dark"
              style={{ width: "100%", marginTop: isMobile ? 0 : "28px" }}
              onClick={handleLoadButtonClick}
            >
              Load Report
            </button>
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">Outlet</label>
            <select
              className="form-select"
              value={selectedOutlet}
              onChange={(e) => setSelectedOutlet(e.target.value)}
            >
              <option value="0">--All--</option>
              {outletList.map((x) => (
                <option key={x.OutletId} value={x.OutletId}>
                  {x.OutletName}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">Sales Group</label>
            <select
              className="form-select"
              value={selectedSalesGroup}
              onChange={(e) => setSelectedSalesGroup(e.target.value)}
            >
              <option value="0">--All--</option>
              {salesGroupList.map((v) => (
                <option key={v.str1} value={v.str1}>
                  {v.str2}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className={`row mt-3 g-3 ${isMobile ? "" : "card-500"}`}>
          {data.map((item) => (
            <div className="col-md-12 col-12" key={item.key}>
              <div className="card card-one d-card">
                <div className="card-body p-3" style={{ color: "black" }}>
                  <div className="d-flex justify-content-start mb-2">
                    <h6 className="fw-bold mb-0">{item.ItemName}</h6>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      {reportType === "1" && (
                        <h6 className="fw-normal">KOT {item.KOT}</h6>
                      )}
                      <h6 className="fw-normal">{item.TableNo}</h6>
                      {reportType === "1" && (
                        <h6 className="fw-normal">
                          {formatDateTime(item.KOTDate)}
                        </h6>
                      )}

                      <p>
                        <span className="text-muted fixed-width-span">Qty</span>
                        <span className="text-muted"> : </span>
                        {item.Qty}
                      </p>
                      <p>
                        <span className="text-muted fixed-width-span">
                          Amount
                        </span>
                        <span className="text-muted"> : </span>
                        {formatCurrency(item.Amount)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {data.length === 0 && (
            <div className="col">
              <div className="card text-dark bg-light mb-2">
                <div className="card-body pb-0">
                  <p className="text-center">
                    <strong>No Record Found</strong>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NegativeKotReport;
