import React, { useEffect, useRef, useState, useContext } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Link } from "react-router-dom";
import UserContext from "../Context/User/userContext";

const Sidebar = ({ user }) => {
  const context = useContext(UserContext);
  const { selectedHotel } = context;
  const [appType, setAppType] = useState(null);

  const sidebarLabelRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const [ps, setPs] = useState(null);

  useEffect(() => {
    setPs(new PerfectScrollbar("#sidebarMenu"));
    return () => {
      if (ps) {
        ps.destroy();
        setPs(null);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedHotel && Boolean(selectedHotel.str1)) {
      setAppType(parseInt(selectedHotel.str3));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel]);

  const HandleSidebarLabelClick = (index) => {
    const sidebarItem = sidebarLabelRefs[index].current.parentNode;
    if (sidebarItem) {
      sidebarItem.classList.toggle("show");
      document.querySelector(".sidebar").classList.remove("footer-menu-show");
      ps.update();
    }
  };

  const HandleSubMenuClick = (e) => {
    e.preventDefault();
    const parentElement = e.target.parentNode;
    let siblingElement = parentElement.previousElementSibling;
    while (siblingElement) {
      siblingElement.classList.remove("show");
      siblingElement = siblingElement.previousElementSibling;
    }

    siblingElement = parentElement.nextElementSibling;
    while (siblingElement) {
      siblingElement.classList.remove("show");
      siblingElement = siblingElement.nextElementSibling;
    }
    parentElement.classList.toggle("show");
    document.querySelector(".sidebar").classList.remove("footer-menu-show");
    ps.update();
  };

  const HandleFooterMenuClick = (e) => {
    const el = e.target.closest(".sidebar");
    if (el) {
      el.classList.toggle("footer-menu-show");
    }
  };

  const handleMenuItemClick = () => {
    document.querySelector("body").classList.toggle("sidebar-show");
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <button type="button" className="sidebar-logo">
          Reports
        </button>
      </div>
      <div id="sidebarMenu" className="sidebar-body">
        { appType!==null && (
          <>
          <div className="nav-group">
          {appType === 0 && (
            <span className="nav-label no-after-style">
              <Link to="" onClick={handleMenuItemClick}>
                Outlet Barcode
              </Link>
            </span>
          )}
          {appType !== 0 && (
            <span className="nav-label no-after-style">
              <Link to="home" onClick={handleMenuItemClick}>
                Dashboard
              </Link>
            </span>
          )}
        </div>
        {appType !== 0 && (
          <>
            <div className="nav-group">
              <span
                className="nav-label"
                ref={sidebarLabelRefs[0]}
                onClick={() => HandleSidebarLabelClick(0)}
              >
                Front Office
              </span>
              <ul className="nav nav-sidebar">
                <li className="nav-item">
                  <Link
                    to="rx02cki"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i>{" "}
                    <span>Room Status</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="rx03rcl"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i>{" "}
                    <span>Reservation Calendar</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="rx04exr"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i>{" "}
                    <span>Expected Arrivals</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="rx05exd"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i>{" "}
                    <span>Expected Departures</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="rx06ckn"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i>{" "}
                    <span>Check In Report</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="rx07cko"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i>{" "}
                    <span>Check Out Report</span>
                  </Link>
                </li>
                <li className="nav-item" style={{ display: "none" }}>
                  <span
                    className="nav-link has-sub"
                    onClick={HandleSubMenuClick}
                  >
                    <i className="ri-gallery-line"></i> Media Gallery
                  </span>
                  <nav className="nav nav-sub">
                    <a
                      href="../apps/gallery-music.html"
                      className="nav-sub-link"
                    >
                      Music Stream
                    </a>
                    <a
                      href="../apps/gallery-video.html"
                      className="nav-sub-link"
                    >
                      Video Stream
                    </a>
                  </nav>
                </li>
              </ul>
            </div>
            <div className="nav-group">
              <span
                className="nav-label"
                ref={sidebarLabelRefs[1]}
                onClick={() => HandleSidebarLabelClick(1)}
              >
                Food and Beverages
              </span>
              <ul className="nav nav-sidebar">
                <li className="nav-item">
                  <Link
                    to="fbx01ts"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i>{" "}
                    <span>Table Status</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="fbx06ds"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i>{" "}
                    <span>Daily Sales Report</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="fbx04is"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i>{" "}
                    <span>Item Wise Sales</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="fbx05gr"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i> <span>GST Report</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="fbx07ck"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i>{" "}
                    <span>Cancelled KOT Report</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="fbx08nk"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i>{" "}
                    <span>Negative KOT Report</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="outlet-barcode"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i>{" "}
                    <span>Outlet Barcode</span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="nav-group">
              <span
                className="nav-label"
                ref={sidebarLabelRefs[2]}
                onClick={() => HandleSidebarLabelClick(2)}
              >
                Store
              </span>
              <ul className="nav nav-sidebar">
                <li className="nav-item">
                  <Link
                    to="srx02ss"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i>{" "}
                    <span>Store Stock</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="srx01er"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i>{" "}
                    <span>Excise Report</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="srx03pr"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i>{" "}
                    <span>Purchase Register</span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="nav-group">
              <span
                className="nav-label"
                ref={sidebarLabelRefs[3]}
                onClick={() => HandleSidebarLabelClick(3)}
              >
                Common Reports
              </span>
              <ul className="nav nav-sidebar">
                <li className="nav-item">
                  <Link
                    to="crz02db"
                    className="nav-link"
                    onClick={handleMenuItemClick}
                  >
                    <i className="ri-folder-2-line"></i> <span>Day Book</span>
                  </Link>
                </li>
              </ul>
            </div>
          </>
        )}
        <div className="nav-group mb-3 d-lg-none">
          <span
            className="nav-label"
            ref={sidebarLabelRefs[4]}
            onClick={() => HandleSidebarLabelClick(4)}
          >
            Settings
          </span>
          <ul className="nav nav-sidebar">
            <li className="nav-item">
              <Link
                to="usx01up"
                className="nav-link"
                onClick={handleMenuItemClick}
              >
                <i className="ri-folder-2-line"></i> <span>Profile</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/login"
                className="nav-link"
                onClick={handleMenuItemClick}
              >
                <i className="ri-folder-2-line"></i> <span>Log Out</span>
              </Link>
            </li>
          </ul>
        </div>
          </>
        )}
      </div>

      <div className="sidebar-footer d-none d-lg-block">
        <div className="sidebar-footer-top">
          <div className="sidebar-footer-thumb">
            <img src="./assets/img/user.png" alt="user" />
          </div>
          <div className="sidebar-footer-body">
            <h6>
              <span>{user.fullName}</span>
            </h6>
            <p>{user.category}</p>
          </div>
          <span
            id="sidebarFooterMenu"
            className="dropdown-link"
            onClick={HandleFooterMenuClick}
          >
            <i className="ri-arrow-down-s-line"></i>
          </span>
        </div>
        <div className="sidebar-footer-menu">
          <nav className="nav">
            <Link to="usx01up">
              <i className="ri-profile-line"></i> View Profile
            </Link>
          </nav>
          <hr />
          <nav className="nav">
            <Link to="/login">
              <i className="ri-logout-box-r-line"></i> Log Out
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
