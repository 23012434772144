import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../Context/User/userContext";
import Api from "../../Helpers/ApiHelper";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import DatePicker from "react-datepicker";

const ItemWiseSale = () => {
  const context = useContext(UserContext);
  const { user, setIsLoading, isMobile, setAlertText, selectedHotel } = context;

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [outletList, setOutletList] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [salesGroupList, setSalesGroupList] = useState([]);
  const [selectedSalesGroup, setSelectedSalesGroup] = useState("");
  const [itemGroupList, setItemGroupList] = useState([]);
  const [selectedItemGroup, setSelectedItemGroup] = useState("");
  const [stewardList, setStewardList] = useState([]);
  const [selectedSteward, setSelectedSteward] = useState("");
  const [itemName, setItemName] = useState("");
  const [data, setData] = useState([]);

  const loadOutlets = async () => {
    if (!selectedHotel) return;
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/store/GetOutlets`, postData, {
        headers: headers,
      });
      if (response.status === 200) {
        setOutletList(response.data);
        setSelectedOutlet("");
      }
    } catch (error) {}
  };

  const loadSalesGroupList = async () => {
    if (!selectedHotel) return;
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(
        `/api/store/GetSalesGroupList`,
        postData,
        {
          headers: headers,
        }
      );
      if (response.status === 200) {
        setSalesGroupList(response.data);
        setSelectedSalesGroup("");
      }
    } catch (error) {}
  };

  const loadItemGroupList = async () => {
    if (!selectedHotel) return;
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/store/GetItemGroups`, postData, {
        headers: headers,
      });
      if (response.status === 200) {
        setItemGroupList(response.data);
        setSelectedItemGroup("");
      }
    } catch (error) {}
  };

  const loadStewardList = async () => {
    if (!selectedHotel) return;
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/store/GetStewards`, postData, {
        headers: headers,
      });
      if (response.status === 200) {
        setStewardList(response.data);
        setSelectedSteward("");
      }
    } catch (error) {}
  };

  const loadData = async () => {
    if (!selectedHotel) return;

    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: format(fromDate, "dd/MM/yyyy"),
      str4: format(toDate, "dd/MM/yyyy"),
      str5: selectedOutlet,
      str6: selectedSalesGroup,
      str7: selectedItemGroup,
      str8: selectedSteward,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/store/GetItemWiseSales`, postData, {
        headers: headers,
      });
      setIsLoading(false);
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedHotel && Boolean(selectedHotel.str1)) {
      loadOutlets().then(() => {
        loadSalesGroupList().then(() => {
          loadItemGroupList().then(() => {
            loadStewardList().then(() => {
              loadData();
            });
          });
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel]);

  function formatDateTime(datetimeString) {
    const formattedDatetime = format(
      new Date(datetimeString),
      "dd MMM, yyyy hh:mm a"
    );
    return formattedDatetime;
  }

  const formatCurrency = (numberString) => {
    const number = parseFloat(numberString);
    const formattedNumber = number.toFixed(2);
    return "₹" + formattedNumber;
  };

  const handleLoadButtonClick = (e) => {
    loadData();
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Item Wise Sales Report</h4>
          </div>
        </div>

        <div className={`row ${isMobile ? "" : "card-top"}`}>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">From Date</label>
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">To Date</label>
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className={`col-12 col-sm-3 mt-2 ${isMobile? "order-last":""}`}>
            <button
              type="button"
              className="btn btn-dark"
              style={{ width: "100%", marginTop: isMobile ? 0 : "28px" }}
              onClick={handleLoadButtonClick}
            >
              Load Report
            </button>
          </div>

          {!isMobile && (<div className="col-12 col-sm-3 mt-2 "></div>)}
         
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">Outlet</label>
            <select
              className="form-select"
              value={selectedOutlet}
              onChange={(e) => setSelectedOutlet(e.target.value)}
            >
              <option value="">--All--</option>
              {outletList.map((x) => (
                <option key={x.str1} value={x.str1}>
                  {x.str2}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">Sales Group</label>
            <select
              className="form-select"
              value={selectedSalesGroup}
              onChange={(e) => setSelectedSalesGroup(e.target.value)}
            >
              <option value="">--All--</option>
              {salesGroupList.map((v) => (
                <option key={v.str1} value={v.str1}>
                  {v.str2}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">Item Group</label>
            <select
              className="form-select"
              value={selectedItemGroup}
              onChange={(e) => setSelectedItemGroup(e.target.value)}
            >
              <option value="">--All--</option>
              {itemGroupList.map((v) => (
                <option key={v.str1} value={v.str1}>
                  {v.str2}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">Steward</label>
            <select
              className="form-select"
              value={selectedSteward}
              onChange={(e) => setSelectedSteward(e.target.value)}
            >
              <option value="">--All--</option>
              {stewardList.map((v) => (
                <option key={v.str1} value={v.str1}>
                  {v.str2}
                </option>
              ))}
            </select>
          </div>
          
          
        </div>

        <div className={`row mt-3 g-3 ${isMobile ? "" : "card-500"}`}>
          {data.map((item) => (
            <div className="col-md-12 col-12" key={item.ItemId}>
              <div className="card card-one d-card">
                <div className="card-body p-3" style={{ color: "black" }}>
                  <div className="d-flex justify-content-start mb-2">
                    <h6 className="fw-bold mb-0">{item.ItemName}</h6>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <h6 className="fw-normal">{item.SalesGroupName}</h6>

                      {(item.Complemenotry > 0) && (
                        <p>
                          <span className="text-muted fixed-width-span">
                            Complementary
                          </span>
                          <span className="text-muted"> : </span>
                          {item.Complemenotry}
                        </p>
                      )}

                      <p>
                        <span className="text-muted fixed-width-span">
                          Sold
                        </span>
                        <span className="text-muted"> : </span>
                        {item.Sold}
                      </p>
                      <p>
                        <span className="text-muted fixed-width-span">
                          Amount
                        </span>
                        <span className="text-muted"> : </span>
                        {formatCurrency(item.Amount)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {data.length === 0 && (
            <div className="col">
              <div className="card text-dark bg-light mb-2">
                <div className="card-body pb-0">
                  <p className="text-center">
                    <strong>No Record Found</strong>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ItemWiseSale;
