import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../../Context/User/userContext";
import Api from "../../../Helpers/ApiHelper";
import { useNavigate } from 'react-router-dom';

const TableStatus = () => {
  const context = useContext(UserContext);
  const { user, setIsLoading, isMobile, setAlertText, selectedHotel } = context;

  const navigate = useNavigate();

  const [outletList, setOutletList] = useState([]);

  const loadOutlets = async () => {
    if (!selectedHotel) return;
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/fnb/GetOutletList`, postData, {
        headers: headers,
      });
      setIsLoading(false);
      if (response.status === 200) {
        setOutletList(response.data);        
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedHotel && Boolean(selectedHotel.str1)) {
      loadOutlets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel]);

  const handleOutletClick = (outlet) => {
    if(outlet){
      navigate('/fbx02ts', { state: outlet });
    }
  };
  

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Table Status</h4>
          </div>
        </div>

        <div className="row g-3">
          {outletList.map((item) => (
            <div className="col-12 col-sm-12" key={item.OutletId}>
              <div
                className="card card-one d-card"
                onClick={()=> handleOutletClick(item)}
              >
                <div className="card-body p-3" style={{ color: "black" }}>
                  <div className="d-flex justify-content-start mb-2">
                    <h6 className="fw-bold mb-0">{item.OutletName}</h6>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <p>{item.OutletGroupName}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {outletList.length === 0 && (
            <div className="col">
              <div className="card text-dark bg-light mb-2">
                <div className="card-body pb-0">
                  <p className="text-center">
                    <strong>No Record Found</strong>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TableStatus;
