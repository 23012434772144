import React, { useState, useEffect, useContext } from "react";
import Footer from "../../Layouts/Footer";
import { Link, useLocation } from "react-router-dom";
import UserContext from "../../Context/User/userContext";
import Api from "../../Helpers/ApiHelper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

const DashboardSalesDetail = () => {
  const location = useLocation();
  const { outlet, frequency } = location.state;
  const context = useContext(UserContext);
  const { user, setIsLoading, isMobile, setAlertText, selectedHotel } = context;

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [data, setData] = useState([]);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (isInitialRender) {
      const startOfMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );
      if (frequency === "Monthly") {
        setFromDate(startOfMonth);
        setToDate(new Date());
      } else {
        setFromDate(new Date());
        setToDate(new Date());
      }
    }
  }, [isInitialRender, frequency]);

  useEffect(() => {
    if (isInitialRender) {
      if (fromDate && toDate) {
        loadData();
      }
    }
    // eslint-disable-next-line
  }, [fromDate, toDate, isInitialRender]);

  const handleLoadButtonClick = (e) => {
    loadData();
  };

  const loadData = async () => {
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: format(fromDate, "dd/MM/yyyy"),
      str4: format(toDate, "dd/MM/yyyy"),
      str5: outlet,
      str6: frequency,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    Api.post(`/api/dashboard/GetSalesDetail`, postData, {
      headers: headers,
    })
      .then((res) => {
        setIsLoading(false);
        if (isInitialRender) setIsInitialRender(false);
        if (res.status === 200) {
          setData(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Collection Detail
              </li>
            </ol>
            <h4 className="main-title mb-0">
              {outlet} {frequency} Collection
            </h4>
          </div>
        </div>

        <div className="row g-3">
          <div className="col-md-3 col-sm-12 mt-3">
            <label className="form-label">
              {frequency === "Monthly" ? "From" : ""} Date
            </label>
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          {frequency === "Monthly" && (
            <div className="col-md-3 col-sm-12 mt-3">
              <label className="form-label">To Date</label>
              <DatePicker
                selected={toDate}
                onChange={(date) => setToDate(date)}
                closeOnScroll={true}
                className="form-control"
                dateFormat="dd/MM/yyyy"
              />
            </div>
          )}
          <div className="col-md-2 col-sm-12 mt-3">
            <button
              type="button"
              className="btn btn-dark"
              style={{ width: "100%", marginTop: isMobile ? 0 : "29px" }}
              onClick={handleLoadButtonClick}
            >
              Load Report
            </button>
          </div>
        </div>

        <div className="row g-3 mt-3">
          <div className="col-12">
            <div className="card-container">
              {data.map((item) => (
                <div className="card text-dark bg-light mb-2" key={item.Sn}>
                  <div className="card-body pb-0">
                    {Boolean(item.Particular) && (
                      <p className="text-uppercase">
                        <strong>{item.Particular}</strong>
                      </p>
                    )}

                    <p className="mb-1">
                      <span className="text-muted fixed-width-span">Date</span>
                      <span className="text-muted"> : </span> {item.VoucherDate}
                    </p>
                    {frequency==="Daily" && outlet==="FnB" && (
                      <p className="mb-1">
                      <span className="text-muted fixed-width-span">Outlet</span>
                      <span className="text-muted"> : </span> {item.OutletName}
                    </p>
                    )}
                    {frequency==="Daily" && (
                      <p className="mb-1">
                      <span className="text-muted fixed-width-span">Bill No</span>
                      <span className="text-muted"> : </span> {item.BillNo}
                    </p>
                    )}
                    <p className="mb-1">
                      <span className="text-muted fixed-width-span">
                        Amount
                      </span>
                      <span className="text-muted"> : </span> ₹{item.Receipt}
                    </p>
                  </div>
                </div>
              ))}
              {data.length === 0 && (
                <div className="card text-dark bg-light mb-2">
                  <div className="card-body pb-0">
                    <p className="text-center">
                      <strong>No Record Found</strong>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default DashboardSalesDetail;
