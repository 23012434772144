import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Login from "./Components/Login";
import LoadingScreen from "./Helpers/LoadingScreen";
import Dashboard from "./Components/Dashboard";
import ConfirmDialog from "./Helpers/ConfirmDialog";
import { useEffect, useContext, useState } from "react";
import UserContext from "./Context/User/userContext";
import { Modal } from "bootstrap";
import EasyAlert from "./Helpers/EasyAlert";
import DashboardSalesDetail from "./Components/CommonReports/DashboardSalesDetail";
import RoomStatus from "./Components/FrontOfficeReports/RoomStatus";
import ReservationCalendar from "./Components/FrontOfficeReports/ReservationCalendar";
import ExpectedArrivals from "./Components/FrontOfficeReports/ExpectedArrivals";
import ExpectedDepartures from "./Components/FrontOfficeReports/ExpectedDepartures";
import CheckInReport from "./Components/FrontOfficeReports/CheckInReport";
import CheckOutReport from "./Components/FrontOfficeReports/CheckOutReport";
import DayBook from "./Components/CommonReports/DayBook";
import TableStatus from "./Components/FnBReports/TableStatus/TableStatus";
import TableDisplay from "./Components/FnBReports/TableStatus/TableDisplay";
import KOTDisplay from "./Components/FnBReports/TableStatus/KOTDisplay";
import ExciseReport from "./Components/StoreReports/ExciseReport";
import StoteStock from "./Components/StoreReports/StoteStock";
import PurchaseRegister from "./Components/StoreReports/PurchaseRegister";
import ItemWiseSale from "./Components/FnBReports/ItemWiseSale";
import GSTReport from "./Components/FnBReports/GSTReport";
import DailySalesReport from "./Components/FnBReports/DailySalesReport";
import CancelKotReport from "./Components/FnBReports/CancelKotReport";
import NegativeKotReport from "./Components/FnBReports/NegativeKotReport";
import Profile from "./Components/UserComponents/Profile";
import GetOutletBarcode from "./Components/NonReport/GetOutletBarcode";
import Download from "./Components/Other/Download";


function App() {
  const context = useContext(UserContext);
  const { selectedHotel, setIsMobile } = context;
  const [appType, setAppType] = useState(null);
  useEffect(() => {
    let bsModal;
    const handlePopstate = () => {
      bsModal?.hide();
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.remove();
      }
    };
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
      window.removeEventListener("popstate", handlePopstate);
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (selectedHotel && Boolean(selectedHotel.str1)) {
      setAppType(parseInt(selectedHotel.str3));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel]);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 500);
  };

  const handleClick = (e) => {
    if (!e.target.closest(".sidebar-footer")) {
      if (document.querySelector(".sidebar")) {
        document.querySelector(".sidebar").classList.remove("footer-menu-show");
      }
    }
  };
  return (
    <div className="App" onClick={handleClick} onTouchStart={handleClick}>
      <LoadingScreen />
      <ConfirmDialog />
      <EasyAlert />
      <Routes>
        <Route exact path="/" element={<Home appType={appType} />}>
          <Route
            index
            element={appType === 0 ? <GetOutletBarcode /> : <Dashboard />}
          />
          <Route
            path="home"
            element={appType === 0 ? <GetOutletBarcode /> : <Dashboard />}
          />
          <Route path="outlet-barcode" element={<GetOutletBarcode />} />
          <Route path="dx01dtl" element={<DashboardSalesDetail />} />
          <Route path="rx02cki" element={<RoomStatus />} />
          <Route path="rx03rcl" element={<ReservationCalendar />} />
          <Route path="rx04exr" element={<ExpectedArrivals />} />
          <Route path="rx05exd" element={<ExpectedDepartures />} />
          <Route path="rx06ckn" element={<CheckInReport />} />
          <Route path="rx07cko" element={<CheckOutReport />} />
          <Route path="crz02db" element={<DayBook />} />
          <Route path="fbx01ts" element={<TableStatus />} />
          <Route path="fbx02ts" element={<TableDisplay />} />
          <Route path="fbx03ts" element={<KOTDisplay />} />
          <Route path="srx01er" element={<ExciseReport />} />
          <Route path="srx02ss" element={<StoteStock />} />
          <Route path="srx03pr" element={<PurchaseRegister />} />
          <Route path="fbx04is" element={<ItemWiseSale />} />
          <Route path="fbx05gr" element={<GSTReport />} />
          <Route path="fbx06ds" element={<DailySalesReport />} />
          <Route path="fbx07ck" element={<CancelKotReport />} />
          <Route path="fbx08nk" element={<NegativeKotReport />} />
          <Route path="usx01up" element={<Profile />} />
        </Route>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/downloads" element={<Download />} />
      </Routes>
    </div>
  );
}

export default App;
