import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../Context/User/userContext";
import Api from "../../Helpers/ApiHelper";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import DatePicker from "react-datepicker";

const PurchaseRegister = () => {
  const context = useContext(UserContext);
  const { user, setIsLoading, isMobile, setAlertText, selectedHotel } = context;

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [storeList, setStoreList] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [itemName, setItemName] = useState("");
  const [data, setData] = useState([]);

  const loadStoreList = async () => {
    if (!selectedHotel) return;
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/store/GetStoreList`, postData, {
        headers: headers,
      });
      if (response.status === 200) {
        setStoreList(response.data);
        setSelectedStore("");
      }
    } catch (error) {}
  };

  const loadVendorList = async () => {
    if (!selectedHotel) return;
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/store/GetVendorList`, postData, {
        headers: headers,
      });
      if (response.status === 200) {
        setVendorList(response.data);
        setSelectedVendor("");
      }
    } catch (error) {}
  };

  const loadData = async () => {
    if (!selectedHotel) return;

    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: format(fromDate, "dd/MM/yyyy"),
      str4: format(toDate, "dd/MM/yyyy"),
      str5: selectedStore,
      str6: selectedVendor,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/store/GetPurchaseRegister`, postData, {
        headers: headers,
      });
      setIsLoading(false);
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedHotel && Boolean(selectedHotel.str1)) {
      loadStoreList().then(() => {
        loadVendorList().then(() => {
          loadData();
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel]);

  function formatDateTime(datetimeString) {
    const formattedDatetime = format(
      new Date(datetimeString),
      "dd MMM, yyyy hh:mm a"
    );
    return formattedDatetime;
  }

  const formatCurrency = (numberString) => {
    const number = parseFloat(numberString);
    const formattedNumber = number.toFixed(2);
    return "₹" + formattedNumber;
  };

  const handleLoadButtonClick = (e) => {
    loadData();
  };

  const handlePurchaseClick = (purchase) => {
    
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Purchase Register</h4>
          </div>
        </div>

        <div className={`row ${isMobile ? "" : "card-top"}`}>
          <div className="col-12 col-sm-2 mt-2">
            <label className="form-label">From Date</label>
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="col-12 col-sm-2 mt-2">
            <label className="form-label">To Date</label>
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">Store</label>
            <select
              className="form-select"
              value={selectedStore}
              onChange={(e) => setSelectedStore(e.target.value)}
            >
              <option value="">--All--</option>
              {storeList.map((store) => (
                <option key={store.str1} value={store.str1}>
                  {store.str2}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">Vendor</label>
            <select
              className="form-select"
              value={selectedVendor}
              onChange={(e) => setSelectedVendor(e.target.value)}
            >
              <option value="">--All--</option>
              {vendorList.map((v) => (
                <option key={v.str1} value={v.str1}>
                  {v.str2}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-2 mt-2">
            <button
              type="button"
              className="btn btn-dark"
              style={{ width: "100%", marginTop: isMobile ? 0 : "28px" }}
              onClick={handleLoadButtonClick}
            >
              Load Report
            </button>
          </div>
        </div>

        <div className={`row mt-3 g-3 ${isMobile ? "" : "card-500"}`}>
          {data.map((item) => (
            <div className="col-md-12 col-12" key={item.PurchaseID}>
              <div className="card card-one d-card" onClick={()=> handlePurchaseClick(item)}>
                <div className="card-body p-3" style={{ color: "black" }}>
                  <div className="d-flex justify-content-start mb-2">
                    <h6 className="fw-bold mb-0">{item.PurchaseNo}</h6>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <h6 className="fw-normal">{item.PurchaseDate}</h6>
                      {selectedStore === "" && (
                        <h6 className="fw-normal">{item.Store}</h6>
                      )}

                      <p>
                        <span className="text-muted fixed-width-span">
                          Invoice No
                        </span>
                        <span className="text-muted"> : </span>
                        {item.InvoiceNo}
                      </p>
                      <p>
                        <span className="text-muted fixed-width-span">
                          Invoice Date
                        </span>
                        <span className="text-muted"> : </span>
                        {item.InvoiceDate}
                      </p>
                      {selectedVendor === "" && (
                        <p>
                          <span className="text-muted fixed-width-span">
                            Vendor
                          </span>
                          <span className="text-muted"> : </span>
                          {item.Vendor}
                        </p>
                      )}
                      <p>
                        <span className="text-muted fixed-width-span">
                          Net Amount
                        </span>
                        <span className="text-muted"> : </span>
                        {formatCurrency(item.NetAmount)}
                      </p>
                      <p>
                        <span className="text-muted fixed-width-span">
                          Mode
                        </span>
                        <span className="text-muted"> : </span>
                        {item.MOP}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {data.length === 0 && (
            <div className="col">
              <div className="card text-dark bg-light mb-2">
                <div className="card-body pb-0">
                  <p className="text-center">
                    <strong>No Record Found</strong>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PurchaseRegister;
