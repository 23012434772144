import React, { useState, useEffect, useContext, useRef } from "react";
import UserContext from "../../Context/User/userContext";
import Api from "../../Helpers/ApiHelper";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { Modal } from "bootstrap";

const DailySalesReport = () => {
  const context = useContext(UserContext);
  const { user, setIsLoading, isMobile, setAlertText, selectedHotel } = context;

  const modalRef = useRef(null);

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [outletList, setOutletList] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [data, setData] = useState([]);
  const [billWiseData, setBillWiseData] = useState([]);

  const loadOutlets = async () => {
    if (!selectedHotel) return;
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/store/GetOutlets`, postData, {
        headers: headers,
      });
      if (response.status === 200) {
        setOutletList(response.data);
        setSelectedOutlet("");
      }
    } catch (error) {}
  };

  const loadData = async () => {
    if (!selectedHotel) return;

    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: format(fromDate, "dd/MM/yyyy"),
      str4: format(toDate, "dd/MM/yyyy"),
      str5: selectedOutlet,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/fnb/GetDailySales`, postData, {
        headers: headers,
      });
      setIsLoading(false);
      if (response.status === 200) {
        // const dataWithKeys = response.data.map((item) => ({
        //   ...item,
        //   key: uuidv4(),
        // }));

        const groupedData = response.data.reduce((acc, item) => {
          if (!acc[item.Dates]) {
            acc[item.Dates] = [];
          }
          acc[item.Dates].push(item);
          return acc;
        }, {});
        setData(groupedData);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const loadBillWiseDataByDate = async (xdate, outletids) => {
    if (!selectedHotel) return;

    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: xdate,
      str4: outletids,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(
        `/api/fnb/GetBillWiseSalesByDate`,
        postData,
        {
          headers: headers,
        }
      );
      setIsLoading(false);
      if (response.status === 200) {
        const dataWithKeys = response.data.map((item) => ({
          ...item,
          key: uuidv4(),
        }));
        setBillWiseData(dataWithKeys);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedHotel && Boolean(selectedHotel.str1)) {
      loadOutlets().then(() => {
        loadData();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel]);

  function formatDateTime(datetimeString) {
    const formattedDatetime = format(
      new Date(datetimeString),
      "dd MMM, yyyy hh:mm a"
    );
    return formattedDatetime;
  }

  const formatCurrency = (numberString) => {
    const number = parseFloat(numberString);
    const formattedNumber = number.toFixed(2);
    return "₹" + formattedNumber;
  };

  const formatProperCase = (text) => {
    if (!text) return "";
    return text
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleLoadButtonClick = (e) => {
    loadData();
  };

  const handleSaleClick = (items) => {
    const xdate = items[0].DisplayDate;
    const outletIds = items.map((item) => item.OutletId).join(",");
    loadBillWiseDataByDate(xdate, outletIds);
    const modalEle = modalRef.current;
    const bsModal = new Modal(modalEle, {});
    bsModal.show();
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Daily Sales Report</h4>
          </div>
        </div>

        <div className={`row ${isMobile ? "" : "card-top"}`}>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">From Date</label>
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">To Date</label>
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">Outlet</label>
            <select
              className="form-select"
              value={selectedOutlet}
              onChange={(e) => setSelectedOutlet(e.target.value)}
            >
              <option value="0">--All--</option>
              {outletList.map((x) => (
                <option key={x.str1} value={x.str1}>
                  {x.str2}
                </option>
              ))}
            </select>
          </div>
          <div
            className={`col-12 col-sm-3 mt-2 ${isMobile ? "order-last" : ""}`}
          >
            <button
              type="button"
              className="btn btn-dark"
              style={{ width: "100%", marginTop: isMobile ? 0 : "28px" }}
              onClick={handleLoadButtonClick}
            >
              Load Report
            </button>
          </div>
        </div>

        <div className={`row mt-3 g-3 ${isMobile ? "" : "card-500"}`}>
          {data &&
            Object.entries(data).map(([date, items]) => (
              <div className="col-md-12 col-12" key={date}>
                <div
                  className="card card-one d-card"
                  onClick={() => handleSaleClick(items)}
                >
                  <div className="card-body p-3" style={{ color: "black" }}>
                    <div className="d-flex justify-content-start mb-2">
                      <h6 className="fw-bold mb-0">{items[0].DisplayDate}</h6>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                        {items.map((item) => (
                          <p key={item.OutletId}>
                            <span className="text-muted fixed-width-span">
                              {formatProperCase(item.OutletName)}
                            </span>
                            <span className="text-muted"> : </span>
                            {formatCurrency(item.NetAmount)}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {(data.length === 0 || Object.keys(data).length === 0) && (
            <div className="col">
              <div className="card text-dark bg-light mb-2">
                <div className="card-body pb-0">
                  <p className="text-center">
                    <strong>No Record Found</strong>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        {billWiseData.length > 0 && (
          <div className="modal fade" tabIndex="-1" ref={modalRef}>
            <div className="modal-dialog modal-lg modal-fullscreen-sm-down">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    F&B Sales - {billWiseData[0].DisplayDate}
                  </h5>
                </div>
                <div className="modal-body">
                  <div className={`row g-3 ${isMobile ? "" : "card-500"}`}>
                    {billWiseData.map((item) => (
                      <div className="col-md-12 col-12" key={item.OMID}>
                        <div className="card card-one d-card">
                          <div
                            className="card-body p-3"
                            style={{ color: "black" }}
                          >
                            <div className="d-flex justify-content-start mb-2">
                              <h6 className="fw-bold mb-0">{item.BillNo}</h6>
                            </div>
                            <div className="row mt-3">
                              <div className="col">
                                <h6 className="fw-normal">
                                  {formatProperCase(item.OutletName)}
                                </h6>

                                <p>
                                  <span className="text-muted fixed-width-span">
                                    Time
                                  </span>
                                  <span className="text-muted"> : </span>
                                  {item.BillTime}
                                </p>
                                <p>
                                  <span className="text-muted fixed-width-span">
                                    Table
                                  </span>
                                  <span className="text-muted"> : </span>
                                  {item.TableNo}
                                </p>
                                <p>
                                  <span className="text-muted fixed-width-span">
                                    Amount
                                  </span>
                                  <span className="text-muted"> : </span>
                                  {formatCurrency(item.NetAmount)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {billWiseData.length === 0 && (
                      <div className="col">
                        <div className="card text-dark bg-light mb-2">
                          <div className="card-body pb-0">
                            <p className="text-center">
                              <strong>No Record Found</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DailySalesReport;
