import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../Context/User/userContext";
import Api from "../../Helpers/ApiHelper";
const GetOutletBarcode = () => {
  const context = useContext(UserContext);
  const { user, setIsLoading, isMobile, selectedHotel } = context;

  const [outletList, setOutletList] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [imageData, setImageData] = useState("");

  useEffect(() => {
    if (selectedHotel && Boolean(selectedHotel.str1)) {
      loadOutlets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel]);

  const loadOutlets = async () => {
    if (!selectedHotel) return;
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/store/GetOutlets`, postData, {
        headers: headers,
      });
      setIsLoading(false);
      if (response.status === 200) {
        setOutletList(response.data);
        setSelectedOutlet("");
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const loadQr = async () => {
    if (!selectedHotel) return;
    if (
      selectedOutlet === "" ||
      selectedOutlet === undefined ||
      selectedOutlet === null
    )
      return;
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: selectedOutlet,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/fnb/GetOutletQrCode`, postData, {
        headers: headers,
        responseType: "arraybuffer",
      });
      if (response.status === 200) {
        const imageBlob = new Blob([response.data], { type: "image/png" });
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result;
          setImageData(dataUrl);
        };
        reader.readAsDataURL(imageBlob);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleLoadButtonClick = (e) => {
    if (
      selectedOutlet === "" ||
      selectedOutlet === undefined ||
      selectedOutlet === null
    ) {
      return;
    }
    loadQr();
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Generate Outlet Barcode</h4>
          </div>
        </div>

        <div className={`row ${isMobile ? "" : "card-top"}`}>
          <div className="col-12 col-sm-3 mt-2">
            <label className="form-label">Outlet</label>
            <select
              className="form-select"
              value={selectedOutlet}
              onChange={(e) => setSelectedOutlet(e.target.value)}
            >
              <option value="0">--Select Outlet--</option>
              {outletList.map((x) => (
                <option key={x.str1} value={x.str1}>
                  {x.str2}
                </option>
              ))}
            </select>
          </div>
          <div
            className={`col-12 col-sm-3 mt-2 ${isMobile ? "order-last" : ""}`}
          >
            <button
              type="button"
              className="btn btn-dark"
              style={{ width: "100%", marginTop: isMobile ? 0 : "28px" }}
              onClick={handleLoadButtonClick}
            >
              Generate
            </button>
          </div>
        </div>

        {imageData && (
          <div className={`row mt-3 g-3 ${isMobile ? "" : "card-500"}`}>
            <div className="col-md-12 col-12">
              <div className="card card-one d-card">
                <div className="card-body p-0">
                  <div className="d-flex align-items-center justify-content-center vh-60">
                    <div className="text-center">
                      <img
                        src={imageData}
                        alt="Outlet Qr Code"
                        className="img-fluids"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GetOutletBarcode;
