import React from 'react'

const Footer = () => {
  return (
    <div className="main-footer mt-5">
        <span>&copy; 2023. CompanyName. All Rights Reserved.</span>
        <span>Created by: <a href="https://google.co.in" rel="noreferrer" target="_blank">CompanyName</a></span>
    </div>
  )
}

export default Footer