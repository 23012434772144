import UserContext from "./userContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJs from "crypto-js";
import Api from "../../Helpers/ApiHelper";

const UserState = (props) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState({
    userId: 0,
    fullName: "",
    category: "",
    token: "",
  });

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [alertText, setAlertText] = useState(null);

  const ValidateUser = async () => {
    try {
      const appCipher = localStorage.getItem("app");
      if (appCipher !== null && appCipher !== undefined) {
        try {
          let appBytes = CryptoJs.AES.decrypt(
            appCipher,
            process.env.REACT_APP_KEY_SECRET
          );
          const suid = appBytes.toString(CryptoJs.enc.Utf8);
          const postData = {
            str1: suid,
          };
          Api.post(`/api/validateToken`, postData)
            .then((res) => {
              if (res.data.str1 === "1") {
                setIsAuthenticated(true);
                const data_string = res.data.str2;
                setUser({
                  userId: data_string.split("_")[0],
                  fullName: data_string.split("_")[2],
                  category: data_string.split("_")[3],
                  token: suid,
                });
              } else {
                setIsAuthenticated(false);
                navigate("/login", { replace: true });
              }
            })
            .catch((error) => {
              setIsAuthenticated(false);
              navigate("/login", { replace: true });
            });
        } catch (error) {
          setIsAuthenticated(false);
          navigate("/login", { replace: true });
        }
      }else{
        setIsAuthenticated(false);
        navigate("/login", { replace: true });
      }
    } catch (error) {
      setIsAuthenticated(false);
      navigate("/login", { replace: true });
    }
  };

  const [confirmText, setConfirmText] = useState(null);

  const [isConfirm, setIsConfirm] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  const [selectedHotel, setSelectedHotel] = useState(null);

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        ValidateUser,
        isLoading,
        setIsLoading,
        alertText,
        setAlertText,
        confirmText,
        setConfirmText,
        isConfirm,
        setIsConfirm,
        isMobile,
        setIsMobile,
        selectedHotel,
        setSelectedHotel
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
