import React, { useContext, useRef, useEffect } from "react";
import UserContext from "../Context/User/userContext";
import { Modal } from "bootstrap";

const ConfirmDialog = () => {
  const context = useContext(UserContext);
  const { confirmText, setConfirmText, setIsConfirm } = context;

  const confirmModalRef = useRef(null);

  const handleConfirm = (e) => {
    setIsConfirm(true);  
    setConfirmText(null);  
    const modalEle = confirmModalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();    
  };

  const handleConfirmClose = (e) => {
    setIsConfirm(false); 
    setConfirmText(null);
    const modalEle = confirmModalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  useEffect(() => {
    if (confirmText && Boolean(confirmText)) {
      setIsConfirm(false); 
      const modalEle = confirmModalRef.current;
      const bsModal = new Modal(modalEle, {
        backdrop: "static",
        keyboard: false,
      });
      bsModal.show();
    }
    // eslint-disable-next-line
  }, [confirmText]);

  return (
    <div
      className="modal fade"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="confirmLabel"
      aria-hidden="true"
      ref={confirmModalRef}
    >
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body pb-0">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <i
                  className="text-warning ri-information-line"
                  style={{ fontSize: "50px" }}
                ></i>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p id="confirmLabel" className="text-center">
                  <strong>{confirmText}</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={handleConfirmClose}
              style={{ minWidth: "100px" }}
            >
              No
            </button>
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={handleConfirm}
              style={{ minWidth: "100px" }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
