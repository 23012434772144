import React from "react";

const Loading = () => {
  return (
    <div className="main main-app p-3 p-lg-4">
      <div className="d-flex justify-content-center">
        <div
          className="spinner-grow"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default Loading;
