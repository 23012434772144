import React, { useState, useEffect, useContext, useRef } from "react";
import UserContext from "../../Context/User/userContext";
import Api from "../../Helpers/ApiHelper";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { Modal } from "bootstrap";

const DayBook = () => {
  const context = useContext(UserContext);
  const { user, setIsLoading, isMobile, selectedHotel } = context;

  const modalRef = useRef();

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedFilterType, setSelectedFilterType] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [data, setData] = useState([]);
  const [openingData, setOpeningData] = useState([]);
  const [mopData, setMopData] = useState([]);
  const [transactionTypeData, setTransactionTypeData] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [commonCollectionData, setCommonCollectionData] = useState([]);

  const FilterType = [
    { str1: "", str2: "None" },
    { str1: "Transaction Type", str2: "Transaction Type" },
    { str1: "MOP", str2: "MOP" },
    { str1: "User Name", str2: "User Name" },
  ];

  const TransactionTypes = [
    { str1: "Restaurant Sale", str2: "Restaurant Sale" },
    { str1: "Bar Sale", str2: "Bar Sale" },
    { str1: "Room Service Sale", str2: "Room Service Sale" },
    { str1: "Reservation Advance", str2: "Reservation Advance" },
    { str1: "Room Advance", str2: "Room Advance" },
    { str1: "Room Check out", str2: "Room Check out" },
    { str1: "Hall Booking Settlement", str2: "Hall Booking Settlement" },
    { str1: "Laundery Billing", str2: "Laundery Billing" },
    { str1: "Special Service", str2: "Special Service" },
    { str1: "Sales Billing", str2: "Sales Billing" },
    { str1: "Receipt", str2: "Receipt" },
    { str1: "BTC Receipt", str2: "BTC Receipt" },
    { str1: "Expenses/Payment", str2: "Expenses/Payment" },
    { str1: "BTC Company Payment to", str2: "BTC Company Payment to" },
    { str1: "Taxi Commission", str2: "Taxi Commission" },
    { str1: "Purchase", str2: "Purchase" },
    { str1: "Return", str2: "Return" },
  ];

  const loadMOPs = async () => {
    if (!selectedHotel) return;
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/reports/GetMOP`, postData, {
        headers: headers,
      });
      setIsLoading(false);
      if (response.status === 200) {
        setFilterList(response.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const loadUsers = async () => {
    if (!selectedHotel) return;
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/reports/GetUsers`, postData, {
        headers: headers,
      });
      setIsLoading(false);
      if (response.status === 200) {
        setFilterList(response.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const loadData = () => {
    setIsLoading(true);
    loadDefaultData().then(() => {
      loadMOPData().then(() => {
        loadTransactionTypeData().then(() => {
          setIsLoading(false);
        });
      });
    });
  };

  const loadDefaultData = async () => {
    if (!selectedHotel) return;
    let trantype = "";
    let mop = "";
    let userid = "";
    if (selectedFilterType && selectedFilterType !== "") {
      if (!selectedFilter) return;
      if (selectedFilterType === "Transaction Type") {
        trantype = selectedFilter;
      } else if (selectedFilterType === "MOP") {
        mop = selectedFilter;
      } else {
        userid = selectedFilter;
      }
    }
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: format(fromDate, "dd/MM/yyyy"),
      str4: format(toDate, "dd/MM/yyyy"),
      str5: trantype,
      str6: mop,
      str7: userid,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(
        `/api/reports/GetDayBookDataDafault`,
        postData,
        {
          headers: headers,
        }
      );
      if (response.status === 200) {
        const dataWithKeys = response.data.map((item) => ({
          ...item,
          key: uuidv4(),
        }));
        const _opData = dataWithKeys.filter(
          (obj) => obj.Particular1 === "Opening"
        );
        setOpeningData(_opData);
        const newData = dataWithKeys.filter(
          (obj) => obj.Particular1 !== "Opening"
        );
        setData(newData);
      }
    } catch (error) {}
  };

  const loadMOPData = async () => {
    if (!selectedHotel) return;
    let trantype = "";
    let mop = "";
    let userid = "";
    if (selectedFilterType && selectedFilterType !== "") {
      if (!selectedFilter) return;
      if (selectedFilterType === "Transaction Type") {
        trantype = selectedFilter;
      } else if (selectedFilterType === "MOP") {
        mop = selectedFilter;
      } else {
        userid = selectedFilter;
      }
    }
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: format(fromDate, "dd/MM/yyyy"),
      str4: format(toDate, "dd/MM/yyyy"),
      str5: trantype,
      str6: mop,
      str7: userid,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(
        `/api/reports/GetDayBookSumByMOP`,
        postData,
        {
          headers: headers,
        }
      );
      if (response.status === 200) {
        const dataWithKeys = response.data.map((item) => ({
          ...item,
          key: uuidv4(),
        }));
        setMopData(dataWithKeys);
      }
    } catch (error) {}
  };

  const loadTransactionTypeData = async () => {
    if (!selectedHotel) return;
    let trantype = "";
    let mop = "";
    let userid = "";
    if (selectedFilterType && selectedFilterType !== "") {
      if (!selectedFilter) return;
      if (selectedFilterType === "Transaction Type") {
        trantype = selectedFilter;
      } else if (selectedFilterType === "MOP") {
        mop = selectedFilter;
      } else {
        userid = selectedFilter;
      }
    }
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: format(fromDate, "dd/MM/yyyy"),
      str4: format(toDate, "dd/MM/yyyy"),
      str5: trantype,
      str6: mop,
      str7: userid,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(
        `/api/reports/GetDayBookSumByType`,
        postData,
        {
          headers: headers,
        }
      );
      if (response.status === 200) {
        const dataWithKeys = response.data.map((item) => ({
          ...item,
          key: uuidv4(),
        }));
        setTransactionTypeData(dataWithKeys);
      }
    } catch (error) {}
  };

  const getCommonCollectionData = async () => {
    if (!selectedHotel) return;
    if (!selectedEntity) return;
    let trantype = "";
    let mop = "";
    let userid = "";
    if (selectedFilterType && selectedFilterType !== "") {
      if (!selectedFilter) return;
      if (selectedFilterType === "Transaction Type") {
        trantype = selectedFilter;
      } else if (selectedFilterType === "MOP") {
        mop = selectedFilter;
      } else {
        userid = selectedFilter;
      }
    }
    if (selectedEntity.xtype === "mop") {
      mop = selectedEntity.CommonId.toString();
    } else if (selectedEntity.xtype === "tran") {
      trantype = selectedEntity.particular;
    }
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: format(fromDate, "dd/MM/yyyy"),
      str4: format(toDate, "dd/MM/yyyy"),
      str5: trantype,
      str6: mop,
      str7: userid,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(
        `/api/reports/GetCommonCollectionData`,
        postData,
        {
          headers: headers,
        }
      );
      if (response.status === 200) {
        const dataWithKeys = response.data.map((item) => ({
          ...item,
          key: uuidv4(),
        }));
        setCommonCollectionData(dataWithKeys);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (selectedHotel && Boolean(selectedHotel.str1)) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel]);

  useEffect(() => {
    if (selectedFilterType && selectedFilterType !== "") {
      if (selectedFilterType === "Transaction Type") {
        setFilterList(TransactionTypes);
      } else if (selectedFilterType === "MOP") {
        loadMOPs();
      } else {
        loadUsers();
      }
    } else {
      setFilterList([]);
    }
    // eslint-disable-next-line
  }, [selectedFilterType]);

  function formatDateTime(datetimeString) {
    const [datePart, timePart] = datetimeString.split(" ");
    const [month, day, year] = datePart.split("/");
    const [hours, minutes] = timePart.split(":");

    const parsedDatetime = new Date(year, month - 1, day, hours, minutes);

    const formattedDatetime = format(parsedDatetime, "dd MMM, yyyy hh:mm a");
    return formattedDatetime;
  }

  const formatTransactionProperty = (propertyName) => {
    const titleCase = propertyName
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    let formattedName = titleCase;

    switch (propertyName) {
      case "RoomCredit":
        formattedName = "Room Credit";
        break;

      default:
        break;
    }

    return formattedName;
  };

  const formatCurrency = (numberString) => {
    const number = parseFloat(numberString);
    const formattedNumber = number.toFixed(2);
    return "₹" + formattedNumber;
  };

  const handleLoadButtonClick = (e) => {
    e.preventDefault();
    loadData();
  };

  const handleMopTapped = (item) => {
    if (item) {
      const xItem = { ...item, xtype: "mop" };
      setSelectedEntity(xItem);
    }
  };

  const handleTransactionTapped = (item) => {
    if (item) {
      const xItem = { ...item, xtype: "tran" };
      setSelectedEntity(xItem);
    }
  };

  useEffect(() => {
    if (selectedEntity) {
      getCommonCollectionData();      
      const modalEle = modalRef.current;
      const bsModal = new Modal(modalEle, {
        backdrop: "static",
        keyboard: false,
      });
      bsModal.show();
    }
    // eslint-disable-next-line
  }, [selectedEntity]);

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Day Book</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-2 mt-1">
            <label className="form-label">From Date</label>
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="col-12 col-sm-2 mt-1">
            <label className="form-label">To Date</label>
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="col-12 col-sm-3 mt-1">
            <label className="form-label">Filter Type</label>
            <select
              className="form-select"
              value={selectedFilterType}
              onChange={(e) => setSelectedFilterType(e.target.value)}
            >
              {FilterType.map((filter) => (
                <option key={filter.str1} value={filter.str1}>
                  {filter.str2}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-3 mt-1">
            <label className="form-label">Filter</label>
            <select
              className="form-select"
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
            >
              {filterList.map((filter) => (
                <option key={filter.str1} value={filter.str1}>
                  {filter.str2}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-2 mt-1">
            <button
              type="button"
              className="btn btn-dark"
              style={{ width: "100%", marginTop: isMobile ? 0 : "28px" }}
              onClick={handleLoadButtonClick}
            >
              Load Report
            </button>
          </div>
        </div>

        <div className="row mt-2 g-3">
          {data.length > 0 && (
            <div className="col">
              <div className="accordion accordion-flush" id="daybookAccordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Openings
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#daybookAccordion"
                  >
                    <div className="accordion-body px-1">
                      <div className="row g-3">
                        {openingData.map((item) => (
                          <div className="col-12 col-sm-12" key={item.key}>
                            <div className="card card-one d-card">
                              <div
                                className="card-body p-3"
                                style={{ color: "black" }}
                              >
                                <div className="d-flex justify-content-start mb-2">
                                  <h6 className="fw-normal mb-0">
                                    {item.commondesc}
                                  </h6>
                                </div>
                                <div className="row mt-3">
                                  <div className="col">
                                    <p>
                                      <span className="text-muted fixed-width-span">
                                        Receipt
                                      </span>
                                      <span className="text-muted"> : </span>
                                      {formatCurrency(item.Receipt)}
                                    </p>
                                    <p>
                                      <span className="text-muted fixed-width-span">
                                        Payment
                                      </span>
                                      <span className="text-muted"> : </span>
                                      {formatCurrency(item.Payment)}
                                    </p>
                                    <p>
                                      <span className="text-muted fixed-width-span">
                                        Allowance
                                      </span>
                                      <span className="text-muted"> : </span>
                                      {formatCurrency(item.Allowance)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Summary By Mode of Payments
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#daybookAccordion"
                  >
                    <div className="accordion-body px-1">
                      <div className="row g-3">
                        {mopData.map((item) => (
                          <div className="col-12 col-sm-12" key={item.key}>
                            <div className="card card-one d-card">
                              <div
                                className="card-body p-3"
                                style={{ color: "black" }}
                                onClick={() => handleMopTapped(item)}
                              >
                                <div className="d-flex justify-content-start mb-2">
                                  <h6 className="fw-normal mb-0">
                                    {item.ModeOfPayment}
                                  </h6>
                                </div>
                                <div className="row mt-3">
                                  <div className="col">
                                    <p>
                                      <span className="text-muted fixed-width-span">
                                        Receipt
                                      </span>
                                      <span className="text-muted"> : </span>
                                      {formatCurrency(item.Receipt)}
                                    </p>
                                    <p>
                                      <span className="text-muted fixed-width-span">
                                        Payment
                                      </span>
                                      <span className="text-muted"> : </span>
                                      {formatCurrency(item.Payment)}
                                    </p>
                                    <p>
                                      <span className="text-muted fixed-width-span">
                                        Allowance
                                      </span>
                                      <span className="text-muted"> : </span>
                                      {formatCurrency(item.Allowance)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      Summary By Transaction Type
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#daybookAccordion"
                  >
                    <div className="accordion-body px-1">
                      <div className="row g-3">
                        {transactionTypeData.map((item) => (
                          <div className="col-12 col-sm-12" key={item.key}>
                            <div className="card card-one d-card">
                              <div
                                className="card-body p-3"
                                style={{ color: "black" }}
                                onClick={() => handleTransactionTapped(item)}
                              >
                                <div className="mb-2">
                                  <h6 className="fw-normal mb-0">
                                    {item.PType}
                                  </h6>
                                  <p>{item.particular}</p>
                                </div>
                                <div className="row mt-3">
                                  <div className="col">
                                    {Object.entries(item).map(
                                      ([key, value]) => {
                                        if (
                                          key !== "key" &&
                                          key !== "PType" &&
                                          key !== "particular" &&
                                          key !== "SettlementAmount" &&
                                          key !== "deptid" &&
                                          key !== "subdeptid"
                                        ) {
                                          return (
                                            <p
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                              }}
                                              key={key}
                                            >
                                              <span className="mr-2 text-muted">
                                                {formatTransactionProperty(key)}
                                              </span>
                                              {formatCurrency(value)}
                                            </p>
                                          );
                                        }
                                        return null;
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      Detail
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#daybookAccordion"
                  >
                    <div className="accordion-body px-1">
                      <div className="row g-3">
                        {data.map((item) => (
                          <div className="col-12 col-sm-12" key={item.key}>
                            <div className="card card-one d-card">
                              <div
                                className="card-body p-3"
                                style={{ color: "black" }}
                              >
                                <div className="mb-2">
                                  <h6 className="fw-normal mb-0">
                                    {item.Particular1}
                                  </h6>
                                  {item.TransactionNo && (
                                    <p>{item.TransactionNo}</p>
                                  )}
                                </div>
                                <div className="row mt-3">
                                  <div className="col">
                                    {item.Receipt > 0 && (
                                      <p>
                                        <span className="text-muted fixed-width-span">
                                          Receipt
                                        </span>
                                        <span className="text-muted"> : </span>
                                        {formatCurrency(item.Receipt)}
                                      </p>
                                    )}

                                    {item.Payment > 0 && (
                                      <p>
                                        <span className="text-muted fixed-width-span">
                                          Payment
                                        </span>
                                        <span className="text-muted"> : </span>
                                        {formatCurrency(item.Payment)}
                                      </p>
                                    )}
                                    <p>
                                      <span className="text-muted fixed-width-span">
                                        Mode
                                      </span>
                                      <span className="text-muted"> : </span>
                                      {formatTransactionProperty(item.MOP)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {data.length === 0 && (
            <div className="col">
              <div className="card text-dark bg-light mb-2">
                <div className="card-body pb-0">
                  <p className="text-center">
                    <strong>No Record Found</strong>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        {selectedEntity && (
          <div className="modal fade" tabIndex="-1" ref={modalRef}>
            <div className="modal-dialog modal-lg modal-fullscreen-sm-down">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {selectedEntity.xtype === "mop"
                      ? selectedEntity.ModeOfPayment
                      : selectedEntity.particular}{" "}
                    Collections
                  </h5>
                </div>
                <div className="modal-body">
                  <div className="row g-3">
                    {commonCollectionData.map((item) => (
                      <div className="col-12 col-sm-12" key={item.key}>
                        <div className="card card-one d-card border border-dark">
                          <div
                            className="card-body p-3"
                            style={{ color: "black" }}
                          >
                            <div className="d-flex justify-content-start mb-2">
                              <h6 className="fw-normal mb-0">
                                {item.VoucherDate}
                              </h6>
                            </div>
                            <div className="row mt-3">
                              <div className="col">
                                {selectedEntity.xtype === "mop" && (
                                  <p>{item.Particular1}</p>
                                )}
                                {item.BookingNo !== "" &&
                                  item.RoomNo !== "" && (
                                    <>
                                      <p>
                                        <span className="text-muted fixed-width-span">
                                          Booking No
                                        </span>
                                        <span className="text-muted"> : </span>{" "}
                                        {item.BookingNo}
                                      </p>
                                      <p>
                                        <span className="text-muted fixed-width-span">
                                          Room No
                                        </span>
                                        <span className="text-muted"> : </span>{" "}
                                        {item.RoomNo}
                                      </p>
                                    </>
                                  )}
                                {(item.BookingNo === "" ||
                                  item.RoomNo === "") && (
                                  <p>
                                    <span className="text-muted fixed-width-span">
                                      Bill No
                                    </span>
                                    <span className="text-muted"> : </span>{" "}
                                    {item.BillNo}
                                  </p>
                                )}
                                {item.Receipt > 0 && (
                                  <p>
                                    <span className="text-muted fixed-width-span">
                                      Receipt
                                    </span>
                                    <span className="text-muted"> : </span>{" "}
                                    {formatCurrency(item.Receipt)}
                                  </p>
                                )}
                                {item.Payment > 0 && (
                                  <p>
                                    <span className="text-muted fixed-width-span">
                                      Payment
                                    </span>
                                    <span className="text-muted"> : </span>{" "}
                                    {formatCurrency(item.Payment)}
                                  </p>
                                )}
                                <p>
                                  <span className="text-muted fixed-width-span">
                                    MOP
                                  </span>
                                  <span className="text-muted"> : </span>{" "}
                                  {item.MOP}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DayBook;
