import React, { useContext, useState, useEffect, useRef } from "react";
import UserContext from "../../Context/User/userContext";
import Api from "../../Helpers/ApiHelper";
import { Modal } from "bootstrap";
import CryptoJs from "crypto-js";
import sha256 from "crypto-js/sha256";

const Profile = () => {
  const context = useContext(UserContext);
  const { user, setIsLoading, isMobile, selectedHotel } = context;

  const modalRef = useRef(null);
  let bsModal;

  const [hotelList, setHotelList] = useState([]);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [easyAlert, setEasyAlert] = useState(null);

  const getHotels = async () => {
    const postData = {
      str1: user.token,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    Api.post(`/api/profile/GetHotelList`, postData, { headers: headers })
      .then((res) => {
        if (res.status === 200) {
          setHotelList(res.data);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getHotels();
    // eslint-disable-next-line
  }, []);

  const handleChangePasswordButtonClick = (e) => {
    e.preventDefault();
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setEasyAlert("");
    const modalEle = modalRef.current;
    bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    if(!Boolean(oldPassword)){
      setEasyAlert("Please Enter Old Password");
      return;
    }
    if(!Boolean(newPassword)){
      setEasyAlert("Please Enter New Password");
      return;
    }
    if(newPassword!== confirmNewPassword){
      setEasyAlert("Passwords does not match");
      return;
    }
    const oldhash = sha256(oldPassword).toString(CryptoJs.enc.Hex);
    const newhash = sha256(newPassword).toString(CryptoJs.enc.Hex);
    updatePassword(oldhash, newhash);
  }

  const updatePassword = async (oldhash, newhash) => {
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: oldhash,
      str3: newhash
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    Api.post(`/api/user/UpdatePassword`, postData, { headers: headers })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setEasyAlert(res.data.str2);
          if(res.data.str1==="1"){   
            const bsModal = Modal.getInstance(modalRef.current);         
            if (bsModal) {
              bsModal.hide();
            };
            console.log(bsModal);
          }
        }else{
          setEasyAlert("Something Went Wrong. Please Try Again");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setEasyAlert(error);
      });
  };

  return (
    <div className="main main-app p-3 p-lg-4">
      <div className="row g-5">
        <div className="col-xl">
          <div className="media-profile mb-5">
            <div className="media-img mb-3 mb-sm-0">
              <img
                src="./assets/img/user.png"
                className="img-fluid"
                alt="user"
              />
            </div>
            <div className="media-body mt-4">
              <h5 className="media-name">{user.fullName}</h5>
              <p className="d-flex gap-2 mb-4">{user.category}</p>
              <button type="button" className="btn btn-sm btn-outline-dark" onClick={handleChangePasswordButtonClick}>Change Password</button>
            </div>
          </div>         
          <div className="row">
            <div className="col">
            <div className="row mt-2 g-3">
                {hotelList.map((item) => (
                  <div className="col-12 col-sm-12" key={item.id}>
                    <div className="card card-one d-card">
                      <div className="card-body p-3" style={{ color: "black" }}>
                        <div className="d-flex justify-content-start mb-2">
                          <h6 className="fw-normal mb-0 text-uppercase">{item.hotel_name}</h6>
                        </div>
                        <div className="row mt-3">
                          <div className="col">
                            <p>{item.short_name}</p>
                            {(item.gstin && item.gstin.length>5 )&& <p>{item.gstin}</p>}
                            {item.phone && (
                              <p>
                                <span className="text-muted fixed-width-span">
                                  Phone
                                </span>
                                <span className="text-muted"> : </span>{" "}
                                {item.phone}
                              </p>
                            )}
                            {item.email && (
                              <p>
                                <span className="text-muted fixed-width-span">
                                  Email
                                </span>
                                <span className="text-muted"> : </span>{" "}
                                {item.email}
                              </p>
                            )}
                            {item.address && (
                              <p>
                                <span className="text-muted fixed-width-span">
                                  Address
                                </span>
                                <span className="text-muted"> : </span>{" "}
                                {item.address}
                              </p>
                            )}
                            {item.city && (
                              <p>
                                <span className="text-muted fixed-width-span">
                                  City
                                </span>
                                <span className="text-muted"> : </span>{" "}
                                {item.city}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>          
        </div>
      </div>

      <div className="modal fade" tabIndex="-1" ref={modalRef}>
            <div className="modal-dialog modal-lg modal-fullscreen-sm-down">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Update Password</h5>
                </div>
                <div className="modal-body">
                  <div className={`row g-3`}>
                  <div className="col-md-12 col-12">
                        <div className="card card-one d-card">
                          <div
                            className="card-body p-3 pt-0"
                            style={{ color: "black" }}
                          >                                                       
                            <div className="row">
                              <div className="col">
                                <label className="form-label">Old Password</label>
                                <input type="password" className="form-control" value={oldPassword} onChange={(e)=> setOldPassword(e.target.value)}/>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col">
                                <label className="form-label">New Password</label>
                                <input type="password" className="form-control" value={newPassword} onChange={(e)=> setNewPassword(e.target.value)}/>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col">
                                <label className="form-label">Confirm New Password</label>
                                <input type="password" className="form-control" value={confirmNewPassword} onChange={(e)=> setConfirmNewPassword(e.target.value)}/>
                              </div>
                            </div>
                            <div className="row mt-2" style={{height: '45px'}}>
                              <div className="col">
                                {Boolean(easyAlert) && (
                                  <div className="alert alert-warning" role="alert">{easyAlert}</div>
                                )}
                              </div>
                              </div> 
                          </div>
                        </div>
                      </div>                   
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"   
                    onClick={handlePasswordChange}                 
                  >
                    Update Password
                  </button>
                </div>
              </div>
            </div>
          </div>
    </div>
  );
};

export default Profile;
