import React, { useState, useEffect, useRef, useContext } from "react";
import { Modal } from "bootstrap";
import UserContext from "../../Context/User/userContext";
import Api from "../../Helpers/ApiHelper";

const RoomStatus = () => {
  const context = useContext(UserContext);
  const { user, setIsLoading, isMobile, setAlertText, selectedHotel } = context;

  const modalRef = useRef();

  const [data, setData] = useState([]);
  const [roomCountData, setRoomCountData] = useState([]);
  const [roomInfo, setRoomInfo] = useState(null);

  const loadData = async () => {
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    Api.post(`/api/frontoffice/GetRoomStatus`, postData, {
      headers: headers,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setData(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (selectedHotel && Boolean(selectedHotel.str1)) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel]);

  useEffect(() => {
    if (data.length > 0) {
      getRoomsCountByStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function getRoomCardColor(status) {
    let color;

    switch (status) {
      case 0:
        color = "green";
        break;
      case 1:
        color = "red";
        break;
      case 2:
        color = "#D6BCBC";
        break;
      case 3:
        color = "#9FB7CF";
        break;
      case 4:
        color = "#D6AE00";
        break;
      case 5:
        color = "#CFA7CF";
        break;
      case 6:
        color = "#E0FFFF";
        break;
      case 7:
        color = "#C7C7AD";
        break;
      default:
        color = "#ffffff";
        break;
    }

    return color;
  }

  function getRoomCardName(status) {
    let name;

    switch (status) {
      case 0:
        name = "Vacant Room";
        break;
      case 1:
        name = "Occupied Room";
        break;
      case 2:
        name = "Dirty Room";
        break;
      case 3:
        name = "Block Room";
        break;
      case 4:
        name = "DNR";
        break;
      case 5:
        name = "Inspect";
        break;
      case 6:
        name = "Touch Up";
        break;
      case 7:
        name = "Repair";
        break;
      default:
        name = "N/A";
        break;
    }

    return name;
  }

  const getRoomsCountByStatus = () => {
    const roomCountMap = {};

    data.forEach((room) => {
      const { RoomStatus } = room;
      if (roomCountMap[RoomStatus]) {
        roomCountMap[RoomStatus] += 1;
      } else {
        roomCountMap[RoomStatus] = 1;
      }
    });

    const roomCountArray = Object.entries(roomCountMap).map(
      ([RoomStatus, RoomCount]) => ({
        RoomStatus: parseInt(RoomStatus),
        RoomCount,
      })
    );

    setRoomCountData(roomCountArray);
  };

  const handleRoomTap = (room) => {
    if (room) {
      setRoomInfo(room);
      setIsLoading(true);
      const postData = {
        str1: user.token,
        str2: selectedHotel.str1,
        str3: room.RoomNo,
        str4: room.HKId.toString(),
        str5: room.RoomStatus.toString(),
      };
      var headers = {
        "Content-Type": "application/json",
        "x-auth-token": `${user.token}`,
      };
      Api.post(`/api/frontoffice/GetRoomInfo`, postData, {
        headers: headers,
      })
        .then((res) => {
          setIsLoading(false);
          if (res.status === 200) {
            setRoomInfo((edata) => ({
              ...edata,
              ...res.data,
            }));
            const modalEle = modalRef.current;
            const bsModal = new Modal(modalEle, {
              backdrop: "static",
              keyboard: false,
            });
            bsModal.show();
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Room Status</h4>
          </div>
        </div>

        <div className="row g-3">
          {data.map((item) => (
            <div className="col-6 col-sm-4" key={item.RoomId}>
              <div
                className="card card-one"
                style={{ backgroundColor: getRoomCardColor(item.RoomStatus) }}
                onClick={() => handleRoomTap(item)}
              >
                <div className="card-body p-3" style={{ color: "black" }}>
                  <div className="d-flex justify-content-center mb-2">
                    <h6 className="fw-normal text-center mb-0">
                      {item.RoomNo}
                    </h6>
                  </div>
                  <p className="xcard-value ls--1 mb-1">
                    {item.RoomCategoryName}
                  </p>
                  <p className="xcard-value ls--1 mb-1">{item.FloorName}</p>
                </div>
              </div>
            </div>
          ))}
          {data.length === 0 && (
            <div className="col">
              <div className="card text-dark bg-light mb-2">
                <div className="card-body pb-0">
                  <p className="text-center">
                    <strong>No Record Found</strong>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="main-footer mt-5">
          <div className="row w-100">
            {roomCountData.map((room) => (
              <div className="col-4 col-sm-4 mt-2" key={room.RoomStatus}>
                <div
                  className="card card-one"
                  style={{ backgroundColor: getRoomCardColor(room.RoomStatus) }}
                >
                  <div className="card-body p-1" style={{ color: "black" }}>
                    <p className="fw-normal text-center mb-0">
                      {getRoomCardName(room.RoomStatus)}
                    </p>
                    <p className="xcard-value ls--1 mb-1">{room.RoomCount}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {roomInfo && (
          <div className="modal fade" tabIndex="-1" ref={modalRef}>
            <div className="modal-dialog modal-fullscreen-sm-down">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Room Info</h5>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="card card-one"
                        style={{
                          backgroundColor: getRoomCardColor(
                            roomInfo.RoomStatus
                          ),
                        }}
                      >
                        <div
                          className="card-body p-3"
                          style={{ color: "black" }}
                        >
                          <div className="d-flex justify-content-center mb-2">
                            <h6 className="fw-normal text-center mb-0">
                              {roomInfo.RoomNo}
                            </h6>
                          </div>
                          <p className="xcard-value ls--1 mb-1">
                            {roomInfo.RoomCategoryName}
                          </p>
                          <p className="xcard-value ls--1 mb-1">
                            {roomInfo.FloorName}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <p>
                            <span className="text-muted fixed-width-span">
                              Status
                            </span>
                            <span className="text-muted"> : </span>{" "}
                            {getRoomCardName(roomInfo.RoomStatus)}
                          </p>
                          {Boolean(roomInfo.BookingNo) && (
                            <p>
                              <span className="text-muted fixed-width-span">
                                Booking No
                              </span>
                              <span className="text-muted"> : </span>{" "}
                              {roomInfo.BookingNo}
                            </p>
                          )}
                          {Boolean(roomInfo.GuestName) && (
                            <p>
                              <span className="text-muted fixed-width-span">
                                Guest Name
                              </span>
                              <span className="text-muted"> : </span>{" "}
                              {roomInfo.GuestName}
                            </p>
                          )}
                          {Boolean(roomInfo.CheckInDate) && (
                            <p>
                              <span className="text-muted fixed-width-span">
                                CheckIn Date
                              </span>
                              <span className="text-muted"> : </span>{" "}
                              {roomInfo.CheckInDate}
                            </p>
                          )}
                          {Boolean(roomInfo.Remark) && (
                            <p>
                              <span className="text-muted fixed-width-span">
                                Remark
                              </span>
                              <span className="text-muted"> : </span>{" "}
                              {roomInfo.Remark}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RoomStatus;
