import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import CryptoJs from "crypto-js";
import sha256 from "crypto-js/sha256";
import AppContext from "../Context/User/userContext";
import Api from "../Helpers/ApiHelper";

const Login = () => {
  const context = useContext(AppContext);
  const { setIsLoading, setUser, setIsAuthenticated, setAlertText } = context;

  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    localStorage.removeItem("app");
  }, []);

  const HandleLoginClick = (e) => {
    e.preventDefault();
    if (userName === "" || userName === undefined) {
      setAlertText("Invalid User Name");
    } else if (password === "" || password === undefined) {
      setAlertText("Invalid Password");
    } else {
      const hash = sha256(password).toString(CryptoJs.enc.Hex);
      Login(userName, hash);
    }
  };

  const Login = async (str1, str2) => {
    setIsLoading(true);
    const postData = {
      str1: str1,
      str2: str2,
    };
    Api.post(`/api/mobileauthentication`, postData)
      .then((res) => {
        setIsLoading(false);
        if (res.data.str1 === "1") {
          setIsAuthenticated(true);
          const data_string = res.data.str3;
          setUser({
            userId: data_string.split("_")[0],
            fullName: data_string.split("_")[2],
            category: data_string.split("_")[3],
            token: res.data.str2,
          });

          const cipherKey = CryptoJs.AES.encrypt(
            res.data.str2,
            process.env.REACT_APP_KEY_SECRET
          ).toString();
          localStorage.setItem("app", cipherKey);
          navigate("/", { replace: true });
        } else {
          setIsAuthenticated(false);
          setAlertText(res.data.str2);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setAlertText(error);
      });
  };  

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light d-none d-lg-block">
        <div className="container px-1 px-lg-1 d-flex justify-content-between">
          <Link className="navbar-brand" to="/">
            Report App
          </Link>
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
          <li className="nav-item active">
          <Link to='/downloads' className="nav-link">
              Downloads
          </Link>
          </li>
          </ul>
          
        </div>
      </nav>
      <div className="page-sign">
        <div className="card card-sign">
          <div className="card-header">
            <Link
              style={{ display: "none" }}
              to="/"
              className="header-logo mb-4"
            >
              company
            </Link>
            <h3 className="card-title">Sign In</h3>
            <p className="card-text">
              Welcome back! Please signin to continue.
            </p>
          </div>
          <div className="card-body">
            <div className="mb-4">
              <label className="form-label">User Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your username"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="form-label d-flex justify-content-between">
                Password{" "}
                <Link to="/" style={{ display: "none" }}>
                  Forgot password?
                </Link>
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              type="button"
              className="btn btn-primary btn-sign"
              onClick={HandleLoginClick}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
