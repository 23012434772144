import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../Context/User/userContext";
import Api from "../../Helpers/ApiHelper";
import { v4 as uuidv4 } from "uuid";
import { format, parse } from "date-fns";
import DatePicker from "react-datepicker";

const CheckInReport = () => {
  const context = useContext(UserContext);
  const { user, setIsLoading, isMobile, setAlertText, selectedHotel } = context;

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [agentList, setAgentList] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(-1);
  const [data, setData] = useState([]);

  const loadAgents = async () => {
    if (!selectedHotel) return;
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(
        `/api/frontoffice/GetAgentList`,
        postData,
        {
          headers: headers,
        }
      );
      setIsLoading(false);
      if (response.status === 200) {
        setAgentList(response.data);
        setSelectedAgent(-1);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const loadData = async () => {
    if (!selectedHotel) return;
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: format(fromDate, "dd/MM/yyyy"),
      str4: format(toDate, "dd/MM/yyyy"),
      str5: selectedAgent ? selectedAgent : -1,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(
        `/api/frontoffice/GetCheckinData`,
        postData,
        {
          headers: headers,
        }
      );
      setIsLoading(false);
      if (response.status === 200) {
        const dataWithKeys = response.data.map((item) => ({
          ...item,
          key: uuidv4(),
        }));
        setData(dataWithKeys);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedHotel && Boolean(selectedHotel.str1)) {
      loadAgents().then(() => {
        loadData();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel]);

  function formatDateTime(datetimeString) {
    const formattedDatetime = format(new Date(datetimeString), 'dd MMM, yyyy hh:mm a');
    return formattedDatetime;
  }

  const handleLoadButtonClick = (e) => {
    e.preventDefault();
    loadData();
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Check In Report</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-3 mt-1">
            <label className="form-label">From Date</label>
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="col-12 col-sm-3 mt-1">
            <label className="form-label">To Date</label>
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="col-12 col-sm-3 mt-1">
            <label className="form-label">Agent</label>
            <select
              className="form-select"
              value={selectedAgent}
              onChange={(e) => setSelectedAgent(parseInt(e.target.value))}
            >
              <option value={-1}>--All--</option>
              {agentList.map((agent) => (
                <option key={agent.str1} value={agent.str1}>
                  {agent.str2}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-sm-3 mt-1">
            <button
              type="button"
              className="btn btn-dark"
              style={{ width: "100%", marginTop: isMobile ? 0 : "28px" }}
              onClick={handleLoadButtonClick}
            >
              Load Report
            </button>
          </div>
        </div>

        <div className="row mt-2 g-3">
          {data.map((item) => (
            <div className="col-12 col-sm-12" key={item.key}>
              <div className="card card-one d-card border border-dark">
                <div className="card-body p-3" style={{ color: "black" }}>
                  <div className="d-flex justify-content-start mb-2">
                    <h6 className="fw-normal mb-0">{item.bookingno}</h6>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <p>{item.GuestName}</p>
                      <p>
                        <span className="text-muted fixed-width-span">
                          Checkin Time
                        </span>
                        <span className="text-muted"> : </span>{" "}
                        {formatDateTime(item.CheckInTime)}
                      </p>
                      <p>
                        <span className="text-muted fixed-width-span">
                          Agent
                        </span>
                        <span className="text-muted"> : </span> {item.AgentName}
                      </p>
                      <p>
                        <span className="text-muted fixed-width-span">
                          Room
                        </span>
                        <span className="text-muted"> : </span> {item.RoomNo}
                      </p>
                      <p>
                        <span className="text-muted fixed-width-span">
                          Room Charge
                        </span>
                        <span className="text-muted"> : </span>
                        {"₹"}
                        {item.RoomCharges}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="mb-0">
                        <span className="text-muted">Pax</span>
                        {" : "}
                        {item.NoOfPax}
                      </p>
                    </div>
                    <div className="col">
                      <p className="mb-0">
                        <span className="text-muted">Plan</span>
                        {" : "}
                        {item.RoomPlanTypeName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {data.length === 0 && (
            <div className="col">
              <div className="card text-dark bg-light mb-2">
                <div className="card-body pb-0">
                  <p className="text-center">
                    <strong>No Record Found</strong>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CheckInReport;
