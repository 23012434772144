import React, { useEffect, useContext } from "react";
import AppContext from "../Context/User/userContext";
import Header from "../Layouts/Header";
import Sidebar from "../Layouts/Sidebar";
import { Outlet } from "react-router-dom";
import Loading from "./Other/Loading";

const Home = (props) => {
  const context = useContext(AppContext);
  const validateUser = context.ValidateUser;
  const { user } = context;
  const { appType } = props;

  useEffect(() => {
    validateUser();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Sidebar user={user} />
      <Header user={user} />
      {appType !== null && <Outlet />}
      {appType === null && <Loading />}
    </>
  );
};

export default Home;
