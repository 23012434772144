import React, { useContext, useRef, useEffect } from "react";
import UserContext from "../Context/User/userContext";
import { Modal } from "bootstrap";

const EasyAlert = () => {
  const context = useContext(UserContext);
  const { alertText, setAlertText } = context;

  const alertModalRef = useRef(null);

  useEffect(() => {
    if (alertText && Boolean(alertText)) {
      const modalEle = alertModalRef.current;
      const bsModal = new Modal(modalEle, {
        backdrop: "static",
        keyboard: false,
      });
      bsModal.show();
    }
    // eslint-disable-next-line
  }, [alertText]);

  const handleAlertClose = (e) => {
    setAlertText(null);
    const modalEle = alertModalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  return (
    <div
      className="modal fade"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="alertLabel"
      aria-hidden="true"
      ref={alertModalRef}
    >
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body pb-0">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <i
                  className="text-warning ri-information-line"
                  style={{ fontSize: "50px" }}
                ></i>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p id="alertLabel" className="text-center">
                  <strong>{alertText}</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={handleAlertClose}
              style={{ minWidth: "100px" }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EasyAlert;
