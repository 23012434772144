import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../Context/User/userContext";
import Api from "../../Helpers/ApiHelper";

import { format, parse } from "date-fns";
import DatePicker from "react-datepicker";

const ExpectedDepartures = () => {
    const context = useContext(UserContext);
    const { user, setIsLoading, isMobile, setAlertText, selectedHotel } = context;
  
    const [date, setDate] = useState(new Date());
    const [data, setData] = useState([]);
  
    const loadData = async () => {
      if (!selectedHotel) return;
      setIsLoading(true);
      const postData = {
        str1: user.token,
        str2: selectedHotel.str1,
        str3: format(date, "dd/MM/yyyy"),
      };
      var headers = {
        "Content-Type": "application/json",
        "x-auth-token": `${user.token}`,
      };
      Api.post(`/api/frontoffice/GetExpectedDepartures`, postData, {
        headers: headers,
      })
        .then((res) => {
          setIsLoading(false);
          if (res.status === 200) {
            setData(res.data);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    };
  
    useEffect(() => {
      if (selectedHotel && Boolean(selectedHotel.str1)) {
        loadData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedHotel]);
  
    useEffect(() => {
      loadData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);
  
    function formatDateTime(datetimeString) {
      const parsedDatetime = parse(
        datetimeString,
        "dd/MM/yyyy HH:mm:ss",
        new Date()
      );
      const formattedDatetime = format(parsedDatetime, "dd MMM, yyyy hh:mm a");
  
      return formattedDatetime;
    }

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Expected Departures</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-3">
            <label className="form-label">Date</label>
            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>

        <div className="row mt-2 g-3">
          {data.map((item) => (
            <div className="col-12 col-sm-12" key={item.RoomDailyId}>
              <div className="card card-one d-card border border-dark">
                <div className="card-body p-3" style={{ color: "black" }}>
                  <div className="d-flex justify-content-start mb-2">
                    <h6 className="fw-normal mb-0">{item.BookingNo}</h6>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <p>
                        <span className="text-muted fixed-width-span">
                          Guest Name
                        </span>
                        <span className="text-muted"> : </span> {item.GuestName}
                      </p>
                      <p>
                        <span className="text-muted fixed-width-span">
                          Checkin Date
                        </span>
                        <span className="text-muted"> : </span>{" "}
                        {item.CheckInDate}
                      </p>
                      <p>
                        <span className="text-muted fixed-width-span">
                          Room
                        </span>
                        <span className="text-muted"> : </span> {item.RoomNo}
                      </p>
                      <p>
                        <span className="text-muted fixed-width-span">
                          Room Category
                        </span>
                        <span className="text-muted"> : </span>{" "}
                        {item.RoomCategoryName}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p className="mb-0">
                        <span className="text-muted">Pax</span>: {item.NoOfPax}
                      </p>
                    </div>
                    <div className="col">
                      <p className="mb-0">
                        <span className="text-muted">Days</span>:{" "}
                        {item.NoOfDays}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {data.length === 0 && (
            <div className="col">
              <div className="card text-dark bg-light mb-2">
                <div className="card-body pb-0">
                  <p className="text-center">
                    <strong>No Record Found</strong>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ExpectedDepartures