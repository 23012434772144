import React, { useEffect, useContext, useState } from "react";
import AppContext from "../Context/User/userContext";
import Api from "../Helpers/ApiHelper";
import { parse, format } from "date-fns";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  LineChart,
  Line,
  AreaChart,
  Area,
  Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { useNavigate  } from 'react-router-dom';

const Dashboard = () => {
  const context = useContext(AppContext);
  const { user, setIsLoading, isMobile, setAlertText, selectedHotel } = context;
  const navigate = useNavigate();

  const [roomStatusData, setRoomStatusData] = useState(null);
  const [collectionData, setCollectionData] = useState(null);
  const [chartData, setChartData] = useState(null);

  const [occupancy, setOccupancy] = useState(0); 

  const [combinedFnbChartData, setCombinedFnbChartData] = useState([]);

  useEffect(() => {
    if (selectedHotel && Boolean(selectedHotel.str1)) {
      const cdate = format(new Date(), "dd/MM/yyyy");
      loadDataSets(cdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel]);

  const loadDataSets = async (xdate) => {
    await getRoomStatusData(xdate);
    await getCollectionData(xdate);
    await getChartData(xdate);
  };

  const getRoomStatusData = async (xdate) => {
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: xdate,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    Api.post(`/api/dashboard/GetRoomStatus`, postData, { headers: headers })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setRoomStatusData(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const getCollectionData = async (xdate) => {
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: xdate,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    Api.post(`/api/dashboard/GetSaleAndPurchaseData`, postData, {
      headers: headers,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setCollectionData(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const getChartData = async (xdate) => {
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: xdate,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    Api.post(`/api/dashboard/GetCollectionChartData`, postData, {
      headers: headers,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setChartData(res.data);          
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (roomStatusData) {
      let xocu = (roomStatusData.OccupiedRooms * 100) / roomStatusData.TotalRooms;
      if (!Number.isNaN(xocu)) {
        setOccupancy(xocu);
      } else {
        setOccupancy(0);
      }
    }
  }, [roomStatusData]); 

  const CustomFrontOfficeTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Collection : ₹${payload[0].value}`}</p>          
        </div>
      );
    }
  
    return null;
  };

  const formatXAxisLabel = (value) => {
    try {
      const date = parse(value, "dd/MM/yyyy", new Date());
      const day = date.getDate();
      const month = date.getMonth() + 1;
      return `${day}/${month}`;
    } catch (error) {
      return value;
    }
  };

  const renderFoAreaChart = (data) => {
    const modifiedData = data.map((item) => ({
      str1: item.str1,
      str2: parseFloat(item.str2),
    }));
    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={modifiedData}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 10
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="str1"           
            tickFormatter={ formatXAxisLabel }
          />
          <YAxis />
          <Tooltip content={CustomFrontOfficeTooltip} />
          <Area
            type="monotone"
            dataKey="str2"
            stroke="#CC0000"
            fill="#ffa500"
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  const createFnbChartData = (...departmentData) => {
    const departmentNames = ["Restaurant", "Room Service", "Bar"];
    const combinedSalesData = {};

    departmentData.forEach((deptData, index) => {
      const department = departmentNames[index];

      deptData.forEach((item) => {
        const { str1, str2 } = item;
        const date = str1;

        if (combinedSalesData[date]) {
          combinedSalesData[date][department] = parseFloat(str2);
        } else {
          combinedSalesData[date] = {
            date,
            [department]: parseFloat(str2),
          };
        }
      });
    });

    return Object.values(combinedSalesData);
  };

  useEffect(() => {
    if (chartData) {      
      const combinedSalesDataArray = createFnbChartData(
        chartData.RestaurentCollection,
        chartData.RoomServiceCollection,
        chartData.BarCollection
      );
      setCombinedFnbChartData(combinedSalesDataArray);
    }
    // eslint-disable-next-lin
  }, [chartData]);  
  
  const renderFnBLineChart = (data) => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={formatXAxisLabel} />
          <YAxis />
          <Tooltip/>
          <Legend />
          <Line
            type="monotone"
            dataKey="Restaurant"
            stroke="#7bcbec"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="Room Service" stroke="#d26574" />
          <Line type="monotone" dataKey="Bar" stroke="#691700" />
        </LineChart>
      </ResponsiveContainer>
    );
  };

   const renderStoreRadarChart = (data) => {
    const modifiedData = data.map((item) => ({
      str1: item.str1,
      str2: parseFloat(item.str2),
    }));
    return (
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={modifiedData}>
          <PolarGrid />
          <PolarAngleAxis dataKey="str1" tickFormatter={formatXAxisLabel}/>          
          <Radar name="Mike" dataKey="str2" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
        </RadarChart>
      </ResponsiveContainer>
    );
  };

  const handleSalesDetail = (outlet, freq) => {
    const props = {
      outlet: outlet,
      frequency: freq,
    };
  
    navigate('/dx01dtl', { state: props });
  };

  return (
    <div className="main main-app p-3 p-lg-4">
      <div className="row g-3">
        <div className="col-12">
          <div className="card rounded-3">
            <div className="row">
              <div className="col-3 col-md-3 pe-1 right-card-border">
                <h5 className="room-card-title">Check In</h5>
                <p className="text-muted text-center mb-1">
                  {roomStatusData ? roomStatusData.CheckinRooms : 0}
                </p>
              </div>

              <div className="col-3 col-md-3 pe-1 right-card-border">
                <h5 className="room-card-title">Check Out</h5>
                <p className="text-muted text-center mb-1">
                  {roomStatusData ? roomStatusData.CheckoutRooms : 0}
                </p>
              </div>

              <div className="col-3 col-md-3 pe-1 right-card-border">
                <h5 className="room-card-title">Booking</h5>
                <p className="text-muted text-center mb-1">
                  {roomStatusData ? roomStatusData.ExpectedArrivals : 0}
                </p>
              </div>

              <div className="col-3 col-md-3 ps-1">
                <h5 className="room-card-title">In House</h5>
                <p className="text-muted text-center mb-1">
                  {roomStatusData ? roomStatusData.OccupiedRooms : 0}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-3 mt-2">
        <div className="col-12">
          <div className="card rounded-3 p-3 pb-2">
            <div className="row">
              <div className="col-8 col-md-8" style={{ paddingLeft: "8px" }}>
                <h5 className="color-card-title">Counts</h5>
                <div className="row default-font">
                  <div className="col-8">Total Pax</div>
                  <div className="col-4 text-end">5/0</div>
                </div>
                <div className="row default-font">
                  <div className="col-8">No Show</div>
                  <div className="col-4 text-end">0</div>
                </div>
                <div className="row default-font">
                  <div className="col-8">Cancelled Bookings</div>
                  <div className="col-4 text-end">0</div>
                </div>
              </div>

              <div className="col-4 col-md-4">
                <h5 className="color-card-title text-center">Occupancy</h5>
                <div className="row">
                  <div
                    className="col-12"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div style={{ height: "60px", width: "60px" }}>
                      <CircularProgressbar
                        value={occupancy}
                        text={`${occupancy.toFixed(0)}%`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-md-flex align-items-center justify-content-between mt-3">
        <div>
          <h4 className="color-card-title" style={{ paddingLeft: "5px" }}>
            Front Office
          </h4>
        </div>
      </div>
      <div className="row g-3">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <div className="card rounded-3" style={{width: '100%', height: isMobile? '200px': '300px'}}>
                {Boolean(chartData) && renderFoAreaChart(chartData.FrontOfficeCollection)}
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-6 col-md-3 col-xl-6">
              <div className="card card-one card-product" onClick={()=> handleSalesDetail("Front Office", "Daily")}>
                <div className="card-body p-3">
                  <div className="d-flex justify-content-center mb-2">
                    <h6 className="fw-normal text-success mb-0">
                      Today's Sale
                    </h6>
                  </div>
                  <h4 className="xcard-value ls--1">
                    <span>₹</span>
                    {collectionData ? collectionData.FrontOfficeSaleToday : 0.0}
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-3 col-xl-6">
              <div className="card card-one card-product" onClick={()=> handleSalesDetail("Front Office", "Monthly")}>
                <div className="card-body p-3">
                  <div className="d-flex justify-content-center mb-2">
                    <h6 className="fw-normal text-success mb-0">
                      Monthly Sale
                    </h6>
                  </div>
                  <h4 className="xcard-value ls--1">
                    <span>₹</span>
                    {collectionData
                      ? collectionData.FrontOfficeSaleMonthly
                      : 0.0}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-md-flex align-items-center justify-content-between mt-3">
        <div>
          <h4 className="color-card-title" style={{ paddingLeft: "5px" }}>
            Food and Beverages
          </h4>
        </div>
      </div>
      <div className="row g-3">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
            <div className="card rounded-3" style={{width: '100%', height: isMobile? '200px': '300px'}}>
                {Boolean(combinedFnbChartData) && renderFnBLineChart(combinedFnbChartData)}
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-6 col-md-3 col-xl-6">
              <div className="card card-one card-product" onClick={()=> handleSalesDetail("FnB", "Daily")}>
                <div className="card-body p-3">
                  <div className="d-flex justify-content-center mb-2">
                    <h6 className="fw-normal text-success mb-0">
                      Today's Sale
                    </h6>
                  </div>
                  <h4 className="xcard-value ls--1">
                    <span>₹</span>
                    {collectionData ? collectionData.FnBSaleToday : 0.0}
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-3 col-xl-6">
              <div className="card card-one card-product" onClick={()=> handleSalesDetail("FnB", "Monthly")}>
                <div className="card-body p-3">
                  <div className="d-flex justify-content-center mb-2">
                    <h6 className="fw-normal text-success mb-0">
                      Monthly Sale
                    </h6>
                  </div>
                  <h4 className="xcard-value ls--1">
                    <span>₹</span>
                    {collectionData ? collectionData.FnBSaleMonthly : 0.0}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-md-flex align-items-center justify-content-between mt-3">
        <div>
          <h4 className="color-card-title" style={{ paddingLeft: "5px" }}>
            Store
          </h4>
        </div>
      </div>
      <div className="row g-3">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
            <div className="card rounded-3" style={{width: '100%', height: isMobile? '200px': '300px'}}>
                {Boolean(chartData) && renderStoreRadarChart(chartData.PurchaseExpense)}
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-6 col-md-3 col-xl-6">
              <div className="card card-one card-product">
                <div className="card-body p-3">
                  <div className="d-flex justify-content-center mb-2">
                    <h6 className="fw-normal text-success mb-0">
                      Today's Purchase
                    </h6>
                  </div>
                  <h4 className="xcard-value ls--1">
                    <span>₹</span>
                    {collectionData ? collectionData.PurchaseToday : 0.0}
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-3 col-xl-6">
              <div className="card card-one card-product">
                <div className="card-body p-3">
                  <div className="d-flex justify-content-center mb-2">
                    <h6 className="fw-normal text-success mb-0">
                      Monthly Purchase
                    </h6>
                  </div>
                  <h4 className="xcard-value ls--1">
                    <span>₹</span>
                    {collectionData ? collectionData.PurchaseMonthly : 0.0}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </div>
  );
};

export default Dashboard;
