import React from 'react'

const Download = () => {
    const epsUrl = process.env.PUBLIC_URL + '/assets/downloads/epsync_x64_dist.zip';
    const oappUrl = process.env.PUBLIC_URL + '/assets/downloads/com.companyname.hotelreports.apk';
  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container px-4 px-lg-5">
                <a className="navbar-brand" href="/downloads">IBS Downloads</a>
            </div>
        </nav>

        <section className="py-5">
            <div className="container px-4 px-lg-5 mt-5">
                <div className="row gx-4 gx-lg-5 row-cols-2 row-cols-md-1 row-cols-xl-2 justify-content-center">
                    <div className="col mb-5">
                        <div className="card h-100 w-100"> 
                            <div className="card-body p-4">
                                <div className="text-center">                                   
                                    <h5 className="fw-bolder">EndPoint Synchronizer</h5>                                   
                                     Database synchronizer for IBS Hotel Management Software
                                </div>
                            </div>
                           
                            <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
                                <div className="text-center"><a className="btn btn-outline-dark mt-auto" href={epsUrl}>Download</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="col mb-5">
                        <div className="card h-100"> 
                            <div className="card-body p-4">
                                <div className="text-center">                                   
                                    <h5 className="fw-bolder">Report App</h5>                                   
                                     Android Owner App for IBS Hotel Management Software
                                </div>
                            </div>
                           
                            <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
                                <div className="text-center"><a className="btn btn-outline-dark mt-auto" href={oappUrl}>Download</a></div>
                            </div>
                        </div>
                    </div>                   
                </div>
            </div>
        </section>
    </>
  )
}

export default Download