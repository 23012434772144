import React, {
  useState,
  useEffect,
  useRef,
  useContext
} from "react";
import UserContext from "../../Context/User/userContext";
import Api from "../../Helpers/ApiHelper";
import { Modal } from "bootstrap";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { v4 as uuidv4 } from 'uuid';

const ReservationCalendar = () => {
  const context = useContext(UserContext);
  const { user, setIsLoading, isMobile, setAlertText, selectedHotel } = context;

  const modalRef = useRef();

  const [date, setDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [reservationDetailData, setReservationDetailData] = useState([]);

  const loadData = async () => {
    if(!selectedHotel) return;
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: format(date, "dd/MM/yyyy"),
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    Api.post(`/api/frontoffice/GetReservationCount`, postData, {
      headers: headers,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setData(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const loadReservationDetailData = async (room) => {
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: format(date, "dd/MM/yyyy"),
      str4: room.RoomCategoryId
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    Api.post(`/api/frontoffice/GetReservationDetail`, postData, {
      headers: headers,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          const dataWithKeys= res.data.map((item)=>({
            ...item,
            key: uuidv4(),
          }));
          setReservationDetailData(dataWithKeys);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (selectedHotel && Boolean(selectedHotel.str1)) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleReservationTap = (room) => {
    if (room) {
      if (room.BookingRooms + room.CheckinRooms + room.BlockRooms > 0) {
        setSelectedReservation(room);
        loadReservationDetailData(room);
        const modalEle = modalRef.current;
        const bsModal = new Modal(modalEle, { 
          backdrop: "static",
          keyboard: false
        });
        bsModal.show();
      }
    }
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Reservation Calendar</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-3">
            <label className="form-label">Date</label>
            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)}
              closeOnScroll={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>

        <div className="row mt-2 g-3">
          {data.map((item) => (
            <div className="col-12 col-sm-4" key={item.RoomCategoryId}>
              <div
                className="card card-one border border-dark"
                onClick={() => handleReservationTap(item)}
              >
                <div className="card-body p-3" style={{ color: "black" }}>
                  <div className="d-flex justify-content-center mb-2">
                    <h6 className="fw-bold text-center mb-0">
                      {item.RoomCategoryName}
                    </h6>
                  </div>
                  <div className="row mt-3">
                    <div className="col">
                      <p className="mb-0">Bookings: {item.BookingRooms}</p>
                    </div>
                    <div className="col">
                      <p className="mb-0">Checkins: {item.CheckinRooms}</p>
                    </div>
                    <div className="col">
                      <p className="mb-0">Block: {item.BlockRooms}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {data.length === 0 && (
            <div className="col">
              <div className="card text-dark bg-light mb-2">
                <div className="card-body pb-0">
                  <p className="text-center">
                    <strong>No Record Found</strong>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        {selectedReservation && (
          <div
            className="modal fade"
            tabIndex="-1"
            ref={modalRef}
            role="dialog"
            data-backdrop="static"
          >
            <div className="modal-dialog modal-lg modal-fullscreen-sm-down">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-center">
                    Reservation Detail
                  </h5>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col">
                      <h6 className="text-center">
                        {selectedReservation.RoomCategoryName}
                      </h6>
                      <h6 className="text-center">
                        {selectedReservation.Date}
                      </h6>
                    </div>
                  </div>
                  <div className="row mt-3 g-3">
                    {reservationDetailData.map((item) => (
                      <div className="col-12 col-sm-12" key={item.key}>
                        <div
                          className={`card card-one d-card border ${
                            item.Type === "Block"
                              ? "border-danger"
                              : "border-dark"
                          }`}
                        >
                          <div
                            className="card-body p-3 pb-1"
                            style={{ color: "black" }}
                          >
                            <div className="d-flex justify-content-start mb-2">
                              <h6 className="fw-bold mb-0">{item.Type}</h6>
                            </div>
                            <div className="row mt-3">
                              <div className="col">
                                {item.Type === "Block" && (
                                  <>
                                    <p>
                                      <span className="text-muted fixed-width-span">
                                        Room
                                      </span>
                                      <span className="text-muted"> : </span>{" "}
                                      {item.NoOfRoomNo}
                                    </p>
                                    <p>
                                      <span className="text-muted fixed-width-span">
                                        Remark
                                      </span>
                                      <span className="text-muted"> : </span>{" "}
                                      {item.GuestName}
                                    </p>
                                  </>
                                )}
                                {item.Type !== "Block" && (
                                  <>
                                    <p>{item.BookingNo}</p>
                                    <p>
                                      <span className="text-muted fixed-width-span">
                                        Guest Name
                                      </span>
                                      <span className="text-muted"> : </span>{" "}
                                      {item.GuestName}
                                    </p>
                                    <p>
                                      <span className="text-muted fixed-width-span">
                                        Phone
                                      </span>
                                      <span className="text-muted"> : </span>{" "}
                                      {item.MobileNo}
                                    </p>
                                    <p>
                                      <span className="text-muted fixed-width-span">
                                        Rooms
                                      </span>
                                      <span className="text-muted"> : </span>{" "}
                                      {item.NoOfRoomNo}
                                    </p>
                                    <p>
                                      <span className="text-muted fixed-width-span">
                                        Days
                                      </span>
                                      <span className="text-muted"> : </span>{" "}
                                      {item.NoOfDays}
                                    </p>
                                    <p>
                                      <span className="text-muted fixed-width-span">
                                        Pax
                                      </span>
                                      <span className="text-muted"> : </span>{" "}
                                      {item.Pax}
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReservationCalendar;
