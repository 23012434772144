import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../../Context/User/userContext";
import Api from "../../../Helpers/ApiHelper";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useNavigate } from "react-router-dom";

const TableDisplay = () => {
  const context = useContext(UserContext);
  const { user, setIsLoading, isMobile, setAlertText, selectedHotel } = context;

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [tableData, setTableData] = useState([]);

  const [firstRunFlag, setFirstRunFlag] = useState(true);

  useEffect(() => {
    if (!location.state) {
      navigate("/fbx01ts");
      return;
    }

    const data = location.state;
    setSelectedOutlet(data);    
  }, [location, navigate]);

  useEffect(() => {
    if(selectedOutlet){
        loadTableData();
    } 
    // eslint-disable-next-line
  }, [selectedOutlet])
  

  const loadTableData = async () => {
    if (!selectedHotel) return;
    if (!selectedOutlet) {
      navigate("/fbx01ts");
      return;
    };    
    setIsLoading(true);
    const postData = {
      str1: user.token,
      str2: selectedHotel.str1,
      str3: selectedOutlet.OutletId.toString(),
      str4: selectedOutlet.OutletGroup.toString()
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    try {
      const response = await Api.post(`/api/fnb/GetTableStatus`, postData, {
        headers: headers,
      });
      setIsLoading(false);
      if (response.status === 200) {
        const dataWithKeys = response.data.map((item) => ({
          ...item,
          key: uuidv4(),
        }));
        setTableData(dataWithKeys);
      }
    } catch (error) {
      setIsLoading(false);
    }
    setFirstRunFlag(false);
  };  

  useEffect(() => {
    if (selectedHotel && Boolean(selectedHotel.str1)) {      
      if (!firstRunFlag) {
        navigate("/fbx01ts");
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel]);

  const formatCurrency = (numberString) => {
    const number = parseFloat(numberString);
    const formattedNumber = number.toFixed(2);
    return "₹" + formattedNumber;
  };

  const getTableColor = (inputString) => {
    switch (inputString.toLowerCase()) {
      case "bill":
        return "#ffb6c1";
      case "settlement":
        return "#90ee90";
      default:
        return "#f5f5f5";
    }
  };

  const handleTableClick = (item) => {
    if (item) {
      navigate("/fbx03ts", { state: item });
    }
  };

  return (
    <>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">
              {selectedOutlet ? selectedOutlet.OutletName : ""}
            </h4>
          </div>
        </div>

        <div className="row g-3">
          {tableData.map((item) => (
            <div className="col-md-3 col-6" key={item.key}>
              <div className="card card-one d-card" style={{backgroundColor: getTableColor(item.Type)}} onClick={()=> handleTableClick(item)}>
                <div className="card-body p-3" style={{ color: "black" }}>
                  <div className="d-flex justify-content-center mb-2">
                    <h6 className="fw-bold text-center mb-0">{item.TableNo}</h6>
                  </div>
                  <div className="row mt-3">
                    <div className="col text-center">
                      <p>{formatCurrency(item.NetAmount)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {tableData.length === 0 && (
            <div className="col">
              <div className="card text-dark bg-light mb-2">
                <div className="card-body pb-0">
                  <p className="text-center">
                    <strong>No Record Found</strong>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TableDisplay;
