import React, { useState, useRef, useContext, useEffect } from "react";
import AppContext from "../Context/User/userContext";
import Api from "../Helpers/ApiHelper";

const Header = ({ user }) => {
  const context = useContext(AppContext);
  const { selectedHotel, setSelectedHotel } = context;

  const [hotelList, setHotelList] = useState([]);

  const menuSidebarRef = useRef(null);

  const menuSidebarClick = (e) => {
    e.preventDefault();
    if (window.matchMedia("(min-width: 992px)").matches) {
      document.querySelector("body").classList.toggle("sidebar-hide");
    } else {
      document.querySelector("body").classList.toggle("sidebar-show");
    }
  };

  const handleFormControlFocus = (e) => {
    const parentElement = e.target.parentNode;
    parentElement.classList.toggle("onfocus");
  };

  const getHotels = async () => {
    const postData = {
      str1: user.token,
    };
    var headers = {
      "Content-Type": "application/json",
      "x-auth-token": `${user.token}`,
    };
    Api.post(`/api/common/GetHotelList`, postData, { headers: headers })
      .then((res) => {
        if (res.status === 200) {
          setHotelList(res.data);
          setSelectedHotel(res.data[0]);          
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (user && Boolean(user.token)) {
      getHotels();
    }
    // eslint-disable-next-line
  }, [user]);

  const handleHotelChange = (e) => {
    const _hotelId = e.target.value;
    if (_hotelId) {
      const _newObj = hotelList.find((data) => data.str1 === _hotelId);
      setSelectedHotel(_newObj);
    }
  };

  return (
    <div className="header-main px-3 px-lg-4">
      <span
        id="menuSidebar"
        ref={menuSidebarRef}
        onClick={menuSidebarClick}
        className="menu-link me-3 me-lg-4"
      >
        <i className="ri-menu-2-fill"></i>
      </span>

      <div className="form-search me-auto">
        <select
          className="form-control"
          value={selectedHotel ? selectedHotel.str1 : ""}
          onChange={handleHotelChange}
        >
          {hotelList.map((hotel) => (
            <option key={hotel.str1} value={hotel.str1}>
              {hotel.str2}
            </option>
          ))}
        </select>
      </div>

      {/* <div className="form-search me-auto">
        <input type="text" className="form-control" placeholder="Search" onFocus={handleFormControlFocus} onBlur={handleFormControlFocus}/>
        <i className="ri-search-line"></i>
      </div> */}

      {/* <div className="dropdown dropdown-skin">
        <a href="" className="dropdown-link" data-bs-toggle="dropdown" data-bs-auto-close="outside"><i className="ri-settings-3-line"></i></a>
        <div className="dropdown-menu dropdown-menu-end mt-10-f">
          <label>Skin Mode</label>
          <nav id="skinMode" className="nav nav-skin">
            <a href="" className="nav-link active">Light</a>
            <a href="" className="nav-link">Dark</a>
          </nav>
          <hr/>
          <label>Sidebar Skin</label>
          <nav id="sidebarSkin" className="nav nav-skin">
            <a href="" className="nav-link active">Default</a>
            <a href="" className="nav-link">Prime</a>
            <a href="" className="nav-link">Dark</a>
          </nav>
        </div>
      </div> */}

      {/* <div className="dropdown dropdown-notification ms-3 ms-xl-4">
        <span href="" className="dropdown-link" data-bs-toggle="dropdown" data-bs-auto-close="outside"><small>3</small><i className="ri-notification-3-line"></i></span>
        <div className="dropdown-menu dropdown-menu-end mt-10-f me--10-f">
          <div className="dropdown-menu-header">
            <h6 className="dropdown-menu-title">Notifications</h6>
          </div>
          <ul className="list-group">
            <li className="list-group-item">
              <div className="avatar online"><img src="https://via.placeholder.com/300/4c5366/fff" alt=""/></div>
              <div className="list-group-body">
                <p><strong>Dominador Manuel</strong> and <strong>100 other people</strong> reacted to your comment "Tell your partner that...</p>
                <span>Aug 20 08:55am</span>
              </div>
            </li>
            <li className="list-group-item">
              <div className="avatar online"><img src="https://via.placeholder.com/300/4c5366/fff" alt=""/></div>
              <div className="list-group-body">
                <p><strong>Angela Ighot</strong> tagged you and <strong>9 others</strong> in a post.</p>
                <span>Aug 18 10:30am</span>
              </div>
            </li>
            <li className="list-group-item">
              <div className="avatar"><span className="avatar-initial bg-primary">a</span></div>
              <div className="list-group-body">
                <p>New listings were added that match your search alert <strong>house for rent</strong></p>
                <span>Aug 15 08:10pm</span>
              </div>
            </li>
            <li className="list-group-item">
              <div className="avatar online"><img src="https://via.placeholder.com/300/4c5366/fff" alt=""/></div>
              <div className="list-group-body">
                <p>Reminder: <strong>Jerry Cuares</strong> invited you to like <strong>Cuares Surveying Services</strong>. <br/><span>Accept</span> or <span>Decline</span></p>
                <span>Aug 14 11:50pm</span>
              </div>
            </li>
            <li className="list-group-item">
              <div className="avatar online"><img src="https://via.placeholder.com/300/4c5366/fff" alt=""/></div>
              <div className="list-group-body">
                <p><strong>Dyanne Aceron</strong> reacted to your post <strong>King of the Bed</strong>.</p>
                <span>Aug 10 05:30am</span>
              </div>
            </li>
          </ul>
          <div className="dropdown-menu-footer"><span>Show all Notifications</span></div>
        </div>
      </div>

      <div className="dropdown dropdown-profile ms-3 ms-xl-4">
          <span className="dropdown-link" data-bs-toggle="dropdown" data-bs-auto-close="outside"><div className="avatar online"><img src="https://via.placeholder.com/500/4c5366/fff" alt=""/></div></span>
          <div className="dropdown-menu dropdown-menu-end mt-10-f">
            <div className="dropdown-menu-body">
              <div className="avatar avatar-xl online mb-3"><img src="https://via.placeholder.com/500/4c5366/fff" alt=""/></div>
              <h5 className="mb-1 text-dark fw-semibold">Shaira Diaz</h5>
              <p className="fs-sm text-secondary">Premium Member</p>

              <nav className="nav">
                <span><i className="ri-edit-2-line"></i> Edit Profile</span>
                <span><i className="ri-profile-line"></i> View Profile</span>
              </nav>
              <hr/>
              <nav className="nav">
                <span><i className="ri-question-line"></i> Help Center</span>
                <span><i className="ri-lock-line"></i> Privacy Settings</span>
                <span><i className="ri-user-settings-line"></i> Account Settings</span>
                <span><i className="ri-logout-box-r-line"></i> Log Out</span>
              </nav>
            </div>
          </div>
      </div> */}
    </div>
  );
};

export default Header;
